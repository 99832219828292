@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';

.mysteryPage {
  display: flex;
  padding: 5vh 20px 100px 100px;
  background: url('./../../assets/images/BG/BGTopAreaFlip.png') no-repeat;
  background-position-x: left;
  background-position-y: bottom;
  justify-content: space-between;
  max-width: 2000px;
  margin: 0 auto;
  gap: 20px;
  font-family: 'aktivgrotesk-reguuploadedfile';
  overflow: hidden;

  @media(min-width: 2000px) {
    max-width: 100%;
    justify-content: center;
    gap: 60px
  }

  @include media-breakpoint-down(lg) {
    padding: 5vh 50px 50px 50px;
  }

  @include media-breakpoint-down(md) {
    padding: 5vh 6vw 7vh 6vw;
  }

  @include media-breakpoint-down(sm) {
    padding: 3vh 3vw 7vh 3vw;
  }

  &:lang(ar) {
    flex-direction: row-reverse;
    padding: 5vh 100px 100px 20px;

    @include media-breakpoint-down(lg) {
      padding: 5vh 50px 50px 50px;
    }
  
    @include media-breakpoint-down(md) {
      padding: 5vh 6vw 7vh 6vw;
    }
  
    @include media-breakpoint-down(sm) {
      padding: 3vh 3vw 7vh 3vw;
    }
  }

}

.mainContent {
  display: flex;
  flex-direction: column;
  gap: 40px;
  max-width: 630px;

  @include media-breakpoint-down(xl) {
    max-width: 480px;
  }

  @include media-breakpoint-down(lg) {
    max-width: 100%;
    width: 100%;
    align-items: center;
    text-align: center;
  }
}

.title {
  font-family: 'summeronregular';
  font-size: 27px;
  line-height: 48px;
  color: #ffffff;
  text-shadow: 3px 3px 0px #000000;

  @include media-breakpoint-down(sm) {
    font-size: 20px;
  }

  &:lang(ar) {
    font-family: 'omnes-arabic', sans-serif;
    font-weight: 800;
    font-style: normal;
    direction: rtl;
    font-size: 58px;
    line-height: 76px;

    @include media-breakpoint-down(md) {
      font-size: 50px;
      line-height: 68px;
    }

    @include media-breakpoint-down(sm) {
      font-size: 40px;
      line-height: 60px;
    }
  }
}

.description {
  font-size: 21px;
  line-height: 28px;
  color: #141414;

  &:lang(ar) {
    font-family: 'omnes-arabic', sans-serif;
    font-weight: 600;
    direction: rtl;
  }

  @include media-breakpoint-down(sm) {
    font-size: 17px;
  }
}

.descriptionLink {
  color: #EE2D24;
  text-decoration: none;
  transition: 0.1s;
  font-weight: 600;

  &:hover {
    color: #cb170d;
  }
} 

.imageWrapSide {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  &:after {
    content: ' ';
    position: absolute;
    z-index: -1;
    background: url(./../../assets/images/BG/sunBG.png) no-repeat center;
    width: 250%;
    height: 120%;
    opacity: 0.9;
    background-size: contain;
  }

  img {
    max-width: 90%;
    height: auto;
    transition: all 0.5s;
  }

  @media(min-width: 2000px) {
    width: unset;
  }

  @include media-breakpoint-down(lg) {
    display: none;
  }
}

.imageWrapUpper {
  display: none;

  &:after {
    content: ' ';
    position: absolute;
    z-index: -1;
    background: url(./../../assets/images/BG/sunBG.png) no-repeat center;
    width: 100%;
    height: 100%;
    background-size: contain;
    transform: scale(2);
  }

  @include media-breakpoint-down(lg) {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

    img {
      max-width: 100%;
      height: auto;
      transition: all 0.5s;
      padding: 15px;
    }
  }
}

.buttonsWrap {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 25px;
  flex-wrap: wrap;
  justify-content: space-evenly;

  @include media-breakpoint-down(md) {
    gap: 15px;
  }

  @include media-breakpoint-down(sm) {
    gap: 10px;
  }
}

.footeringButtons {
  display: flex;
  flex-direction: column;
  gap: 15px;
  width: 100%;

  @include media-breakpoint-down(sm) {
    gap: 8px;
  }

  &:lang(ar) {
    direction: rtl;
  }
}

.inputWrapper {
  display: flex;
  gap: 15px;

  @include media-breakpoint-down(sm) {
    gap: 8px;
  }

  input::placeholder {
    opacity: 0.7;
  }
}

.customInput {
  height: 50px;
  background: #ffffff;
  border-radius: 7px;
  border: none;
  outline: none;
  padding: 20px;
  width: 70%;
  font-size: 16px;

  @include media-breakpoint-down(sm) {
    padding: 15px;
  }

  &:lang(ar) {
    font-family: 'Noto Sans Arabic', sans-serif;
    font-weight: 600;
    direction: rtl;
    font-size: 16px;
  }
  
}

.customInputError {
  height: 50px;
  background: #ffd6d6;
  border-radius: 7px;
  border: 2px solid #ce2121;
  outline: none;
  padding: 20px;
  width: 70%;
  font-size: 16px;

  @include media-breakpoint-down(sm) {
    padding: 15px;
  }
}

.translateButton {
  width: 30%;
  height: 50px;
  background: #ffd84e;
  border-radius: 7px;
  border: none;
  outline: none;
  color: #524516;
  font-weight: 600;
  font-size: 18px;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    background: #ffe485;
  }

  &:lang(ar) {
    font-family: 'Noto Sans Arabic', sans-serif;
    font-weight: 600;
    direction: rtl;
    padding: 0 10px;
    font-size: 16px;

    @media(max-width: 500px) {
      font-size: 14px;
    }
  }
}

.submitButton {
  width: 100%;
  height: 60px;
  left: 225px;
  top: 880px;
  background: #ee2d24;
  color: white;
  border-radius: 7px;
  border: none;
  outline: none;
  font-size: 20px;
  transition: 0.1s;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    background: #de2c23;
  }

  @include media-breakpoint-down(lg) {
    height: 50px;
    font-size: 18px;
  }

  &:lang(ar) {
    font-family: 'Noto Sans Arabic', sans-serif;
    font-weight: 600;
    direction: rtl;
    font-size: 16px;

    @media(max-width: 500px) {
      font-size: 14px;
    }
  }
}

.redButtonViewFull {
  align-self: center;
}