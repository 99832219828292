@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';

.learningPage {
  padding: 20vh 20px 100px 20px;
  margin: 0 auto;
  font-family: 'aktivgrotesk-reguuploadedfile';
  background: url('./../../assets/images/BG/BGTopAreaFlip.png') no-repeat;
  background-position-x: left;
  background-position-y: bottom;

  @include media-breakpoint-down(lg) {
    padding-top: 15vh;
  }

  @include media-breakpoint-down(md) {
    padding-bottom: 90px;
    padding-top: 14vh;
  }

  @include media-breakpoint-down(sm) {
    padding-bottom: 40px;
  }
}

.learnHeading {
  display: flex;
  flex-direction: column;
  max-width: 1340px;
  margin: 0 auto;
  gap: 10px;
  justify-content: space-between;
  margin-bottom: 80px;
  align-items: center;
  text-align: center;
  padding-top: 20px;

  @include media-breakpoint-down(md) {
    margin-bottom: 50px;
  }
}

.headingContent {
  display: flex;
  flex-direction: column;
  gap: 30px;
  max-width: 700px;
  align-items: center;

  p {
    font-size: 21px;
    line-height: 28px;
    color: #141414;

    &:lang(ar) {
      font-family: 'omnes-arabic', sans-serif;
      font-weight: 600;
      direction: rtl;
      font-size: 24px;
      line-height: 140%;
    }
  }

  @include media-breakpoint-down(md) {
    align-items: center;
    gap: 20px;
  }
}

.title {
  font-family: 'summeronregular';
  font-size: 31px;
  line-height: 55px;
  color: #ffffff;
  text-shadow: 3px 3px 0px #000000;

  @include media-breakpoint-down(sm) {
    padding: 0 35px;
  }

  @media(max-width: 400px) {
    padding: 0;
    font-size: 28px;
  }

  &:lang(ar) {
    font-family: 'omnes-arabic', sans-serif;
    font-weight: 800;
    font-style: normal;
    direction: rtl;
    font-size: 58px;
    line-height: 70px;
  }
}

.link {
  color: #EE2D24;
  text-decoration: none;
  transition: 0.1s;
  font-weight: 600;

  &:hover {
    color: #cb170d;
  }
}

.tabsWrap {
  display: flex;
  justify-content: space-between;
  background-color: white;
  border-radius: 7px;
  padding: 5px;
  gap: 5px;
  width: fit-content;
}

.tabActive {
  outline: none;
  border: none;
  width: 189px;
  height: 46px;
  background: #ee2d24;
  border-radius: 4px;
  color: white;
  font-size: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: center;

  @include media-breakpoint-down(sm) {
    width: 130px;
    height: 36px;
  }
}

.tab {
  outline: none;
  border: none;
  width: 189px;
  height: 46px;
  background: #ffffff;
  border-radius: 4px;
  color: rgb(0, 0, 0);
  font-size: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: center;

  @include media-breakpoint-down(sm) {
    width: 130px;
    height: 36px;
  }
}

.imageWrap {
  max-width: 620px;
  display: flex;
  flex-direction: column;
  gap: 60px;
  margin-bottom: 30px;

  @include media-breakpoint-down(sm) {
    flex-direction: column-reverse;
    gap: 40px;
  }

  &:after {
    content: ' ';
    position: absolute;
    z-index: -1;
    left: 0;
    top: 0;
    background: url(./../../assets/images/BG/sunBG.png) no-repeat center;
    width: 100%;
    height: 50%;
    background-size: cover;

    @include media-breakpoint-down(md) {
      top: 20vh;
      height: 35%;
    }
  }

  img {
    max-width: 100%;
    height: auto;
    transition: all 0.5s;
  }
}

.keyboard {
  max-width: 1340px;
  margin: 0 auto;
}
