.redBtn {
  display: flex;
  padding: 8px 12px 0;
  width: 247px;
  height: 65px;
  font-weight: normal;
  text-align: center;
  font-size: 24px;
  align-content: center;
  justify-content: center;
  text-decoration: none;
  font-family: "coolveticaregular", Arial, Helvetica, sans-serif;
  color: white!important;
  background: url("../../../assets/images/redBtnBG.png") no-repeat;
  background-size: contain;
  transition: all ease-in-out 0.15s;

  &:lang(ar){
    font-family: 'Noto Sans Arabic', sans-serif;
  }

  &:hover {
    transform: scale(1.1);
  }
}

.yellowBtn {
  display: flex;
  padding: 8px 12px 0;
  width: 247px;
  height: 65px;
  font-weight: normal;
  text-align: center;
  font-size: 24px;
  align-content: center;
  justify-content: center;
  text-decoration: none;
  font-family: "coolveticaregular", Arial, Helvetica, sans-serif;
  color: #181818 !important;
  background: url("../../../assets/images/yellowBtnBG.png") no-repeat;
  background-size: contain;
  transition: all ease-in-out 0.15s;
  &:lang(ar){
      font-family: 'Noto Sans Arabic', sans-serif;
  }
  
  &:hover {
      transform: scale(1.1);
  }
}

.biggerBtn {
  display: flex;
  padding: 8px 12px 0 12px;
  width: 280px;
  height: 65px;
  font-weight: normal;
  text-align: center;
  font-size: 22px;
  align-content: center;
  justify-content: center;
  text-decoration: none;
  font-family: "coolveticaregular", Arial, Helvetica, sans-serif;
  color: white!important;
  background: url("../../../assets/images/redBtnBig.png") no-repeat;
  background-size: contain;
  transition: all ease-in-out 0.15s;
  
  &:lang(ar){
    font-family: 'Noto Sans Arabic', sans-serif;
  }

  &:hover {
    transform: scale(1.1);
  }

  @media(max-width: 360px) {
    font-size: 20px;
    width: 260px;
    height: 60px;
  }
}