@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";

.footer {
    transition: all 0.5s;
    width: 100%;
    padding: 20px;
    display: flex;
    height: auto;
    font-size: 13px;
    flex-direction: column; 
    align-items:flex-start;
    background: #0E0E0E;
    .btnWrap {
        display: flex;
        padding: 5px 0;
    }

    span {
        display: flex;
        color: #F4F4F4;
        padding: 5px 0;
    }
    
    a {
        color: #F4F4F4!important;
        padding-right:20px;
    }

    @include media-breakpoint-up(md) {
        flex-direction: column; 
        align-items: center;
        text-align: center;
        .btnWrap a {
            padding:0 10px;
        }
    }
}