@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';

.garameesh {
    width: 100%;

    .garameeshWrapper {
        width: 100%;
        display: inline-block;

        ul {
            list-style: none;
            display: flex;
            align-items: center;
            padding: 0;
            margin: 20px 0;

            &:lang(ar) {
                font-family: 'Noto Sans Arabic' !important;
            }
        }

        .link .chat {
            color: #000 !important;
            font-weight: bold;
        }

        .link {
            text-align: center !important;
            display: none;

            &:lang(ar) {
                text-align: center !important;
            }
        }

        .w40 {
            width: 40%;
        }

        .garameeshLangBox {
            display: inline;
            float: left;
            background-color: white;
            text-align: center;
            //padding: 12px;
            font-size: 18px;
            padding: 20px;
            border-radius: 6px;

            @include media-breakpoint-down(sm) {
                padding: 15px;
                font-size: 14px !important;
            }

            @include media-breakpoint-down(md) {
                padding: 15px;
                font-size: 14px !important;
            }

            @include media-breakpoint-down(lg) {
                padding: 15px 10px !important;
                font-size: 16px;
            }

            @include media-breakpoint-down(xl) {
                padding: 15px;
            }
        }

        .garameeshSwap {
            width: 20%;
            display: inline;
            float: left;
            text-align: center;
            padding: 12px;

            .swap {
                cursor: pointer;
            }
        }

        .btn {

            &:lang(ar) {
                direction: rtl;
            }

            @include media-breakpoint-down(md) {
                margin: 0 auto;
            }
        }

        .textarea {
            width: 100%;
            height: 160px;
            //border-radius: 2px;
            border-radius: 7px;
            border-top: 20px solid white;
            border-bottom: 20px solid white;
            border-right: 20px solid white;
            border-left: 20px solid white;
            padding-right: 5px;
            //padding: 20px;
            color: #42280F;
            resize: none;

            &::placeholder {
                color: #bbb;
            }
            /* width */
            &::-webkit-scrollbar {
                width: 6px;
                background: #F7F7F7;
                border-radius: 10px;
            }
            /* Track */
            &::-webkit-scrollbar-track {
                box-shadow: inset 0 0 5px #F7F7F7;
                border-radius: 10px;
            }
            /* Handle */
            &::-webkit-scrollbar-thumb {
                background: #CACACA;
                border-radius: 10px;
            }

            &:focus-visible {
                outline: 0;
            }

            &:lang(ar) {
                direction: rtl;
                font-family: 'Noto Sans Arabic';
            }
            &:disabled{
                background-color: #fff !important;
            }
        }
        // for image upload
        .fileupload {
            background-color: transparent;
            width: 100%;
            margin: 0 auto;
            // padding: 20px;
        }

        .fileUploadBtn {
            width: 100%;
            margin: 0;
            color: #fff;
            // background: #1FB264;
            border: none;
            padding: 10px;
            border-radius: 4px;
            border-bottom: 4px solid #15824B;
            transition: all .2s ease;
            outline: none;
            text-transform: uppercase;
            font-weight: 700;
        }

        .fileUploadBtn:hover {
            // background: #1AA059;
            color: #ffffff;
            transition: all .2s ease;
            cursor: pointer;
        }

        .fileUploadBtn:active {
            border: 0;
            transition: all .2s ease;
        }


        .fileUploadContent {
            // display: none;
            text-align: center;
            position: relative;
            width: 100%;
            height: 93px;
            background: #FFF6A9;
            border-radius: 7px;
            display: flex;
            align-items: center;

            .w20 {
                width: 20%;
                float: left;

                img {
                    margin: 27% auto;
                    cursor: pointer;
                }

                .imgClose {
                    cursor: pointer;
                }
            }

            .w60 {
                width: 60%;
                float: left;
                text-align: left;

                span {
                    margin: 27% auto;
                    //display: table-caption;
                    display: inline;
                }

                &:lang(ar) {
                    text-align: right;
                }
            }

            &:lang(ar) {
                direction: rtl;
            }
        }

        .fileUploadinput {
            position: absolute;
            margin: 0;
            padding: 0;
            width: 100%;
            height: 100%;
            outline: none;
            opacity: 0;
            cursor: pointer;
            display: flex;

            &:lang(ar) {
                font-family: 'Noto Sans Arabic' !important;
            }
        }

        .imageUploadWrap {
            //margin-top: 20px;
            border: 2px dashed #B39738;
            position: relative;
            border-radius: 7px;
        }

        .image-dropping,
        .imageUploadWrap:hover {
            // background-color: #1FB264;
            border: 2px dashed #B39738;
        }

        .imageTitleWrap {
            padding: 0 15px 15px 15px;
            color: #222;
        }

        .dragText {
            text-align: center;

            &:lang(ar) {
                font-family: 'Noto Sans Arabic' !important;
            }
        }

        .dragText h3 {
            font-weight: 100;
            color: #473D19;
            font-size: 16px;
            //padding: 60px 0;
            padding: 45px 0;
            margin: 0;
            justify-content: center;
            align-items: center;
            display: flex;

            &:lang(ar) {
                flex-direction: row-reverse;
            }
        }

        .dragText h3 img {
            margin-right: 8px;

            &:lang(ar) {
                margin-right: 0px;
                margin-left: 8px;
            }
        }

        .fileUploadImage {
            max-height: 200px;
            max-width: 200px;
            margin: auto;
            padding: 20px;
        }

        .removeImage {
            width: 200px;
            margin: 0;
            color: #fff;
            background: #cd4535;
            border: none;
            padding: 10px;
            border-radius: 4px;
            border-bottom: 4px solid #b02818;
            transition: all .2s ease;
            outline: none;
            text-transform: uppercase;
            font-weight: 700;
        }

        .remove-image:hover {
            background: #c13b2a;
            color: #ffffff;
            transition: all .2s ease;
            cursor: pointer;
        }

        .remove-image:active {
            border: 0;
            transition: all .2s ease;
        }

        .textErrorWrapper {
            text-align: left;
            position: relative;
            padding: 22px;
            background: #FFC9C9;
            border-radius: 7px;
            color: #E31515;
            font-family: 'Lato';
            font-style: normal;
            font-weight: 400;
            font-size: 13px;
            border: 1px solid #E31515;
            display: inline-flex;
            width: 100%;
            //justify-content: center;
            justify-content: space-between;
            align-items: center;

            .w80 {
                width: 80%;

                @include media-breakpoint-down(md) {
                    width: 70%;
                }

                &:lang(ar) {
                    text-align: right;
                }
            }


            .w20 {
                width: 10%;


                &:lang(ar) {
                    margin-left: 10px;
                }
            }

            .error_msg {
                font-size: 16px;
                font-family: 'aktivgrotesk-reguuploadedfile';

                @include media-breakpoint-down(md) {
                    font-size: 14px;
                }

                &:lang(ar) {
                    font-family: 'Noto Sans Arabic';
                }
            }

            .imgClose {
                cursor: pointer;
            }

            img {
                margin-right: 10px;
            }

            &:lang(ar) {
                direction: rtl;
                justify-content: space-between;
            }

            @include media-breakpoint-down(md) {
                padding: 15px;
            }
        }

        .wp {
            display: none;
        }
    }
}

.disabledBtn {
    display: flex;
    //padding: 8px 12px 0px 12px;
    align-items: center;
    padding: 0 !important;
    border-radius: 7px;
    width: 100%;
    height: 65px;
    font-weight: normal;
    text-align: center;
    font-size: 22px;
    align-content: center;
    justify-content: center;
    text-decoration: none;
    //font-family: "coolveticaregular", Arial, Helvetica, sans-serif;
    color: #99822F !important;
    background: url("../../../assets/images/Home/disabledBtn.png") no-repeat;
    background-size: cover;
    transition: all ease-in-out 0.15s;
    margin: 20px 0;

    &:lang(ar) {
        font-family: 'Noto Sans Arabic' !important;
    }
}

.btn {
    display: flex;
    padding: 12px;
    width: 100%;
    // height: 65px;
    font-weight: normal;
    text-align: center;
    font-size: 22px;
    align-content: center;
    justify-content: center;
    text-decoration: none;
    font-family: "coolveticaregular", Arial, Helvetica, sans-serif;
    color: white !important;
    background: #EE2D24;
    border-radius: 7px;
    transition: all ease-in-out 0.15s;
    cursor: pointer;
    margin: 20px 0;

    &:hover {
        transform: scale(1.1);

        @include media-breakpoint-down(md) {
            transform: scale(1.05);
        }
    }

    &:lang(ar) {
        font-family: 'Noto Sans Arabic' !important;
    }
}

    #congratsPopup {
        z-index: 9;
    }

    a.chat {
        color: #000 !important;
        font-weight: bold;
    }
    
    .link {
        text-align: center !important;
        // display: none;
        &:lang(ar) {
            text-align: center !important;
        }
    }