@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";


.recipeBanner {
    display: flex;
    padding-bottom: 50px;
    justify-content: center;
    align-content: center; 
    position: relative;
        
    .wrapper {
        width: 500;
        max-width: 100vw; 
        overflow: hidden;
        display: flex;
        flex-direction: column;
        align-items: center;

        h1 {
            padding:0 20px;
            font-family: 'summeronregular';
            font-size: 25px;
            line-height: 48px;

            color: black;

            &:lang(ar) {
                font-family: omnes-arabic, sans-serif;  
                font-weight: 800;
                font-style: normal;
            }
        }
        p {
            padding:10px 20px;
            font-family: 'aktivgrotesk-reguuploadedfile';
            font-size: 16px;
            line-height: 20px;
            color: #151515;

            &:lang(ar){
                font-family: 'Noto Sans Arabic', sans-serif;
                direction: rtl;
            }
        }
        .bannerImg {
            content: " ";
            z-index: 1;
            transition: all 0.5s;
            position: relative;
            display: flex;
            width: 120%;
            top: -50px;
            justify-content: center;
            align-self: center;
            background-size: contain;
            height: auto;
        }
        .btn {
            z-index: 2;
            margin-top: -50px;
        }
    }

    &:after {
        content: " ";
        position: absolute;
        z-index: -1;
        left: 0;
        bottom: 0;
        background: url(../../../assets/images/BG/BGTopAreaFlip1.png) no-repeat center top;

        width: 100%;
        height: 200px;
        background-size: cover;

    }

    @include media-breakpoint-up(sm) {
        .wrapper {
            max-width: 100vw;

            h1, p {
                max-width: 80%;
                text-align: center;
            }

            h1 {
                max-width: 80%;
            }

            p {
                max-width: 60%;
            }

            .bannerImg {
                width: 80vw;
            }
            .btn {
                margin-top: -50px;
            }
        }
    }

    @include media-breakpoint-up(md) {
        .wrapper {
            max-width: 80vw;

            h1, p {
                max-width: 80%;
                text-align: center;
            }
            .bannerImg {
                width: 70vw;
            }
        }
    }

    @include media-breakpoint-up(lg) {
        padding-top: 115px;
        .wrapper {
            max-width: 100vw;

            h1, p {
                max-width: 900px;
                text-align: center;
            }

            h1 {
                font-size: 48px;
                line-height: 80px;
            }

            p {
                font-size: 21px;
                line-height: 28px;
            }
            .bannerImg {
                width: 700px;
            }
        }
    }
}