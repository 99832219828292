@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";


.products {
    display: flex;
    padding: 50px 0;
    justify-content: center;
    align-content: center;
    position: relative;
    background-color: #FFDD58;

    .wrapper {
        width: 100vw;
        max-width: 100vw;
        overflow: hidden;
        padding: 0px;
        ;
        display: flex;
        flex-direction: column;
        align-items: center;
        z-index: 10000;

        h1, p {
            text-align: center;
        }

        h1 {
            padding: 0 20px;
            font-family: 'summeronregular';
            font-size: 27px;
            line-height: 48px;
            color: #FFFFFF;
            text-shadow: 3px 3px 0px #000000;

            &:lang(ar) {
                font-family: omnes-arabic, sans-serif;
                font-weight: 800;
                font-style: normal;
                font-size: 58px;
                line-height: 70px;
            }
        }

        p {
            padding: 20px;
            font-family: 'aktivgrotesk-reguuploadedfile';
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 20px;
            color: #151515;

            &:lang(ar) {
                font-family: 'Noto Sans Arabic', sans-serif;
                padding: 0 20px;
            }
        }

        .btn {
            z-index: 2;
        }
    }

    &:before {
        content: " ";
        position: absolute;
        z-index: 1;
        top: 0;
        right: 0;
        // background: url("../../../assets/images/BG/BGTopArea.png") no-repeat center top;
        background: url("../../../assets/images/BG/BGTopArea1.png") no-repeat center top;
        width: 100%;
        height: 400px;
        overflow: visible;
        background-size: cover;

        @include media-breakpoint-down(md) {
            display: none;
        }
    }

    &:after {
        display: none;
        content: " ";
        position: absolute;
        z-index: 1;
        left: 0;
        bottom: 0;
        // background: url("../../../assets/images/BG/BGTopAreaFlip1.png") no-repeat center top;
        background: url("../../../assets/images/BG/BGTopAreaFlip.png") no-repeat center top;
        width: 100%;
        height: 400px;
        background-size: cover;

        @include media-breakpoint-down(md) {
            display: none;
        }
    }

    @include media-breakpoint-up(sm) {
        .wrapper {
            max-width: 100%;
            margin: 0 auto;

            h1, p {
                max-width: 80%;
                text-align: center;
            }

            h1 {
                max-width: 80%;
            }

            p {
                max-width: 60%;
            }
        }
    }

    @include media-breakpoint-up(md) {
        .wrapper {
            h1, p {
                max-width: 80%;
            }
        }
    }

    @include media-breakpoint-up(lg) {
        padding-top: 100px;
        padding-bottom: 100px;

        .wrapper {
            h1, p {
                max-width: 900px;
                text-align: center;
            }

            h1 {
                font-size: 48px;
                line-height: 80px;
            }

            p {
                font-size: 21px;
                line-height: 28px;
            }
        }
    }
}