@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";

.item {
    margin: 10px;
    display: flex;
    align-items: center;
    text-align: center;
    flex-direction: row;
    overflow-x: hidden;   
    flex-direction: column;
    max-width: 100%;
    color: #141414!important;
    text-decoration:none!important;
    border-radius: 7px;

    @include media-breakpoint-down(md) {
       max-width: 250px;
    }

    @include media-breakpoint-down(sm) {
       max-width: 180px;
    }

    @media(max-width: 450px) {
      max-width: 130px;
   }

   @media(max-width: 350px) {
    max-width: 110px;
  }

    p {
        padding: 20px 0 0;
        display: flex;
        font-size: 15px;

        &:lang(ar){
            font-family: 'Noto Sans Arabic', sans-serif;
            direction: rtl;
        }
    }
}

.hiddenTitle {
  position: relative;
  padding: 20px 0 0;
  color: #0000002e;

  p {
    position: absolute;
    bottom: 0;
    right: 0;
    left: 10px;
    top: 0;
    max-width: 70%;
    overflow: hidden;
    height: 100%;
    opacity: 0.7;
    z-index: -1;
  }
}

.blurredImageWrapper {
  border-radius: 7px;
  overflow: hidden;
}

.recipeImage {
  width: 100%;
  height: auto;
  border-radius: 7px;
}

.recipeImageBlurred {
  width: 100%;
  height: auto;
  filter: blur(12px);
}

@include media-breakpoint-up(md) {
  .item {
      max-width: 250px;
      p {
        font-size: 18px;
      }
  }
}

@include media-breakpoint-up(lg) {
  .item {
      max-width: 250px;
      margin: 20px;
      p {
          font-size: 16px;
      }
  }
}