/* customization of the sidebar */
.burgerButton {
  border: none;
  background: none;
  padding: 0;
}

.sidebar-footer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 20px;
  position: absolute;
  bottom: 10vh;
  padding-left: 50px;
  padding-right: 50px;
}

.sidebar-footer-link {
  font-weight: 500;
  font-size: 21px;
  text-decoration-line: underline;
  color: #6a3806;
  cursor: pointer;
  transition: 0.2s;
}

.sidebar-footer-link:hover {
  color: black;
}

.MuiDrawer-paperAnchorLeft,
.MuiDrawer-paperAnchorRight {
  background-color: #fd952f !important;
  padding-top: 10vh;
  overflow: visible !important;
  background: url(./../assets/images/BG/BGTopAreaRotated.png) no-repeat center
    top;

  &:lang(ar) {
    direction: rtl;
  }

  .MuiListItem-gutters {
    padding-left: 40px;
    padding-right: 40px;

    @media(min-width: 1800px) {
      padding-left: 50px;
      padding-right: 50px;
    }
  }
}

.pageSeparatorSidebarLeft {
  position: absolute;
  bottom: 0;
  right: -36px;
  width: 60px;
  height: 100%;
  z-index: -1;

  &:lang(ar) {
    left: -36px;
    right: unset;
    rotate: -180deg;
  }
}

// ! mobile sidebar

.MuiDrawer-paperAnchorTop {
  background: url(./../assets/images/BG/BGTopArea.png) no-repeat center top;
  background-color: #fd952f !important;
  overflow: visible !important;
  padding: 2vh;
  width: 100%;

  &:lang(ar) {
    direction: rtl;
    padding-right: 20px;
  }
}

.pageSeparatorSidebarTop {
  position: absolute;
  bottom: 12px;
  height: 30px;
  left: 0;
  height: 100vw;
  transform-origin: bottom left;
  transform: rotate(90deg);
}

.sidebar-nav-item {
  font-family: 'summeronregular';
  text-shadow: 3px 3px 0px #000000;
  text-decoration: none !important;
  text-overflow: ellipsis;
  overflow: hidden;
  font-size: 25px;
  color: #ffffff;
  line-height: 200%;

  @media(min-width: 1800px) {
    font-size: 32px;
  }
}

.sidebar-nav-item:hover {
  color: #ffffff;
}

.sidebar-nav-item:lang(ar) {
  font-size: 40px;
  direction: rtl;
  line-height: 140%;
  font-family: 'omnes-arabic', sans-serif;
  font-weight: 800;
  font-style: normal;

  @media(max-width: 800px) {
    font-size: 32px;
  }

  @media(max-width: 700px) {
    font-size: 30px;
  }

  @media(max-width: 650px) {
    font-size: 28px;
  }

  @media(min-width: 1800px) {
    font-size: 48px;
  }
  
  @media(min-width: 2000px) {
    font-size: 54px;
    line-height: 130%;
  }
}

.active {
  text-shadow: 5px 5px 0px #000000 !important;
}

@media screen and (min-width: 1800px) {
  .MuiDrawer-paperAnchorLeft,
  .MuiDrawer-paperAnchorRight {
    width: 30vw;

    &:lang(ar) {
      width: 25vw;
    }
  }
}

@media screen and (max-width: 1000px) {
  .sidebar-nav-item {
    font-size: 20px;
  }
}

@media screen and (max-width: 796px) {
  .MuiDrawer-paperAnchorLeft,
  .MuiDrawer-paperAnchorRight {
    width: 50vw;
  }
}

@media screen and (max-width: 599px) {

  .MuiListItem-root {
    padding: 0 !important;
  }

  .MuiButtonBase-root {
    padding-left: 8px !important;
    padding-right: 0 !important;
  }

  .sidebar-footer {
    padding-left: 8px;
    gap: 10px;
  }

  .sidebar-footer-link {
    font-size: 16px !important;
  }
}
