@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';

.productsPage {
  max-width: 2000px;
  margin: 0 auto;
  padding-bottom: 150px;
  background: url('./../../assets/images/BG/BGTopAreaFlip1.png') no-repeat;
  background-position-x: left;
  background-position-y: bottom;

  @media(min-width: 2000px) {
    max-width: 100%;
    padding-bottom: 200px;
  }

  @include media-breakpoint-down(sm) {
    padding-bottom: 50px;
  }
}

.productsWrap {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  justify-items: center;
  max-width: 1400px;
  gap: 30px;
  margin: 0 auto;

  @media(max-width: 1280px) {
    grid-template-columns: 1fr 1fr;
  }

  @media(max-width: 660px) {
    grid-template-columns: 1fr;
  }
}

.title {
    font-family: 'summeronregular';
    font-size: 37px;
    line-height: 67px;
    color: #FFFFFF;
    text-shadow: 3px 3px 0px #000000;
    text-align: center;
    margin-bottom: 80px;
    margin-top: 20px;
  
    &:lang(ar) {
      direction: rtl;
      font-size: 40px;
      font-size: 50px;
      font-family: omnes-arabic, sans-serif;
      font-style: normal;
      font-weight: 800;
    }

    @include media-breakpoint-down(sm) {
      margin-bottom: 50px;
      font-size: 32px;
    }
}

.card {
  margin: 0 10px;
  text-decoration: none !important;

  .wrap {
    width: 290px;
    height: 367px;
    box-shadow: 0px 5px 6px rgba(0, 0, 0, 0.4);
    border-radius: 7px;
    padding: 30px;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;

    img {
      height: 100%;
      width: auto;
    }

    &:lang(ar){
      .use-spring-carousel-item .ProductCarousel_card__nLOs2 {
        flex-direction: column-reverse;
      }
    }
  }

  h3 {
    font-family: 'coolveticaregular';
    font-style: normal;
    font-weight: 400;
    font-size: 23px;
    text-align: center;
    margin: 30px 0 50px;
    line-height: 32px;
    color: white;
    text-decoration: none!important;
    text-shadow: none;

    &:lang(ar) {
      font-family: 'Noto Sans Arabic', sans-serif;
      direction: rtl;
    }
  }
}