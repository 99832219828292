@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';

.registrationPage {
  display: flex;
  overflow: hidden;
}

.leftSide {
  width: 50%;
  padding: 150px 50px;
  background: url('./../../assets/images/BG/BGTopAreaFlip.png') no-repeat;
  background-position-x: left;
  background-position-y: bottom;
  background-color: #ffd84e;
  display: flex;
  flex-direction: column;
  color: #000000;

  @include media-breakpoint-down(lg) {
    width: 100%;
    background: url('./../../assets/images/BG/BGTopAreaFlip.png') left bottom
        no-repeat,
      url('./../../assets/images/BG/BGTopArea1.png') right top no-repeat;
    background-color: #ffd84e;
    background-size: 140%;
    background-blend-mode: color-burn;
  }

  @include media-breakpoint-down(sm) {
    padding: 110px 20px 50px 20px;
  }
}

.content {
  max-width: 560px;
  display: flex;
  flex-direction: column;

  @include media-breakpoint-down(lg) {
    margin: 0 auto;
  }

  &:lang(ar) {
    direction: rtl;
  }
}

.title {
  font-family: 'summeronregular';
  font-size: 27px;
  line-height: 48px;
  color: #ffffff;
  text-shadow: 3px 3px 0px #000000;
  margin-bottom: 30px;

  &:lang(ar) {
    font-family: 'omnes-arabic', sans-serif;
    font-weight: 800;
    font-style: normal;
    direction: rtl;
    font-size: 58px;
    line-height: 70px;
    margin-bottom: 30px;
  }
}

.description {
  font-size: 21px;
  margin-bottom: 20px;

  @include media-breakpoint-down(sm) {
    font-size: 18px;
  }
}

.formWrapper {
  display: flex;
  flex-direction: column;
  gap: 20px;

  @include media-breakpoint-down(sm) {
    gap: 10px;
  }
}

.customInputWrap {
  display: flex;
  font-size: 16px;
  flex-direction: column;
  gap: 10px;
  width: 100%;

  @include media-breakpoint-down(sm) {
    font-size: 14px;
    gap: 6px;
  }
}

.formError {
  color: #e31515;
  font-size: 18px;
  display: flex;
  gap: 10px;
  align-items: center;

  @include media-breakpoint-down(sm) {
    font-size: 16px;
  }
}

.invisible {
  opacity: 0;
}

.namesWrap {
  display: flex;
  align-items: center;
  gap: 10px;
}

.customInput {
  width: 100%;
  background: #ffffff;
  border-radius: 7px;
  height: 50px;
  border: transparent;
  padding: 15px;
  outline: none;
}

.customInputError {
  width: 100%;
  background: #ffffff;
  border-radius: 7px;
  height: 50px;
  border: 2px solid #e31515;
  padding: 15px;
  outline: none;
}

.userAgreement {
  font-size: 18px;
  line-height: 22px;
  padding: 4vh 0;

  a {
    color: #ee2d24;

    &:active {
      color: #5c1410;
    }

    &:visited {
      color: #ee2d24;
    }
  }

  @include media-breakpoint-down(sm) {
    font-size: 16px;
  }
}

.redBtn {
  display: flex;
  padding: 8px 12px 0;
  width: 247px;
  height: 65px;
  font-weight: normal;
  text-align: center;
  font-size: 24px;
  align-content: center;
  justify-content: center;
  text-decoration: none;
  font-family: 'coolveticaregular', Arial, Helvetica, sans-serif;
  color: white !important;
  background: url('./../../assets/images/redBtnBG.png') no-repeat;
  background-size: contain;
  transition: all ease-in-out 0.15s;
  outline: none;
  border: none;

  &:lang(ar) {
    font-family: 'Noto Sans Arabic', sans-serif;
    direction: rtl;
  }

  &:hover {
    transform: scale(1.1);
  }

  @include media-breakpoint-down(lg) {
    margin: 0 auto;
  }
}

// right side

.decorationImage {
  right: 0;
  top: 0;
  bottom: 0;
  width: 50%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 100px;

  &:after {
    content: ' ';
    position: absolute;
    z-index: -1;
    background: url(./../../assets/images/BG/sunBG.png) no-repeat center;
    width: 250%;
    height: 130%;
    background-size: contain;
  }

  @include media-breakpoint-down(lg) {
    display: none;
  }
}

.registrationSandwich {
  width: 100%;
  height: auto;
  transition: all 0.5s;
  padding: 0 40px;
  max-width: 600px;
}

.pageSeparator {
  height: 30px;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 12px;
  transform-origin: top left;
  transform: rotate(90deg);
}
