@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';

.playButtonWrap {
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
}

.helowbitch {
  background: red;
}

.playButton {
  width: 100px;
  height: 100px;
  background: #fff6a9;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid transparent;
  padding-right: 0;

  &:active {
    border: 3px solid #ffbe84;
  }

  // &:focus {
  //   background: #6cabf3;
  // }

  &:target {
    background: #6cabf3;
  }

  @include media-breakpoint-down(sm) {
    width: 80px;
    height: 80px;
  }

  @media (max-width: 400px) {
    width: 65px;
    height: 65px;
    padding: 0;
  }
}

.playButtonActive {
  width: 100px;
  height: 100px;
  background: blue;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid transparent;
  padding-right: 0;

  @include media-breakpoint-down(sm) {
    width: 80px;
    height: 80px;
  }

  @media (max-width: 400px) {
    width: 65px;
    height: 65px;
    padding: 0;
  }
}

.playArrow {
  @include media-breakpoint-down(sm) {
    padding: 3px 3px 3px 5px;
  }
}

.playButtonCorrect {
  width: 100px;
  height: 100px;
  background: #c6e7b7;
  border: 2px solid #7aab62;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;

  @include media-breakpoint-down(sm) {
    width: 80px;
    height: 80px;
  }

  @media (max-width: 400px) {
    width: 65px;
    height: 65px;
  }
}

.productTitle {
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  color: #1c1c1c;

  &:lang(ar) {
    direction: rtl;
    font-family: 'Noto Sans Arabic', sans-serif;
  }
}
