@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";

.randomizer {
    display: flex;
    padding: 100px 20px;
    margin-top: -17px;
    justify-content: center;
    align-content: center;
    //background-color: #fd9332;
    background-color: #FFDD58;
    position: relative;

    .wrapper {
        position: relative;
        padding: 0px;
        display: flex;
        align-items: center;
        z-index: 2;
        gap: 10vw;

        &:lang(ar) {
            direction: rtl;
        }

        h1 {
            font-family: 'summeronregular';
            font-size: 27px;
            line-height: 48px;
            color: #FFFFFF;
            text-shadow: 3px 3px 0px #000000;

            &:lang(ar) {
                font-family: omnes-arabic, sans-serif;
                font-weight: 800;
                font-style: normal;
                direction: rtl;
                font-size: 58px;
                line-height: 70px;
            }
        }

        p {
            font-family: 'aktivgrotesk-reguuploadedfile';
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 20px;
            color: #151515;

            &:lang(ar) {
                font-family: 'Noto Sans Arabic', sans-serif;
                direction: rtl;
                padding: 0;
            }
        }

        .btnWrap {
            display: flex;
            gap: 20px;

            @media(max-width: 1000px) {
                flex-direction: column;
                margin-top: 15px;
            }
        }

        .btn {
            z-index: 2;
            cursor: pointer;
        }

        .content {
            display: flex;
            flex-direction: column;
            gap: 40px;
        }

        .container {
            position: relative;
            min-height: 400px;

            @media(max-width:768px) {
                min-height: unset;
            }

            .card {
                margin: 0 10px;
                z-index: 3;
                position: absolute;
                top: 50%;
                left: 50%;
                margin-left: -145px;
                margin-top: -183px;

                .wrap {
                    width: 290px;
                    height: 367px;
                    border-radius: 7px;
                    justify-content: center;
                    align-items: center;
                    display: flex;
                    flex-direction: column;
                    position: relative;
                    overflow: hidden;

                    &:before {
                        content: " ";
                        position: absolute;
                        width: 100%;
                        height: 100%;
                        left: 0;
                        top: 0;
                        background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.7) 85.42%);
                    }

                    img {
                        height: 100%;
                        width: auto;
                    }

                    span {
                        padding: 20px;
                        position: absolute;
                        bottom: 0;
                        font-family: 'coolveticaregular';
                        font-size: 21px;
                        line-height: 24px;
                        color: #FFFFFF;

                        &:lang(ar) {
                            font-family: 'Noto Sans Arabic', sans-serif;
                        }
                    }
                }
            }

            .player {
                width: 300px;
                height: 300px;
                max-width: 800px;
                position: relative;

                &:after {
                    content: " ";
                    width: 100%;
                    height: 100%;
                    position: absolute;
                    left: 0;
                    top: 0;
                    // border: 5px solid #ffd74e;
                    //border: 5px solid #fd9332;
                }
            }
        }
    }

    &:before {
        content: " ";
        position: absolute;
        z-index: 1;
        top: 0;
        right: 0;
        background: url("../../../assets/images/BG/BGTopArea1.png") no-repeat center top;
        //background: url("../../../assets/images/BG/BGTopArea.png") no-repeat center top;

        width: 100%;
        height: 400px;
        background-size: cover;

        @include media-breakpoint-down(md) {
            display: none;
        }
    }

    &:after {
        display: none;
        content: " ";
        position: absolute;
        z-index: 1;
        left: 0;
        bottom: 0;
        //background: url(../../../assets/images/BG/BGTopAreaFlip1.png) no-repeat center top;
        background: url("../../../assets/images/BG/BGTopAreaFlip.png") no-repeat center top;
        width: 100%;
        height: 400px;
        background-size: cover;
    }

    @include media-breakpoint-up(sm) {
        .wrapper {
            max-width: 100vw;

            h1, p {
                max-width: 80%;
            }

            h1 {
                max-width: 80%;
            }

            p {
                max-width: 60%;
            }

            .player {
                width: 500px;
                height: 500px;
            }
        }
    }

    @include media-breakpoint-up(md) {
        .wrapper {
            max-width: 80vw;

            h1, p {
                max-width: 80%;
            }

            h1 {
                font-size: 48px;
                line-height: 80px;
            }

            p {
                font-size: 21px;
                line-height: 28px;
            }
        }
    }

    @include media-breakpoint-down(md) {
        .wrapper {
            flex-direction: column-reverse;
        }

        .content {
            align-items: center;
            text-align: center;
        }
    }

    @include media-breakpoint-up(lg) {
        .wrapper {
            max-width: 100vw;
            padding-bottom: 30px;

            h1, p {
                max-width: 900px;
            }
        }
    }
}