.react-tel-input {
  .form-control {
    font-family: 'aktivgrotesk-reguuploadedfile' !important;

    font-size: 16px !important;
    height: 50px !important;
    border-radius: 7px !important;
    border: none !important;
    padding: 15px;
    outline: none;
    padding-left: 58px !important;
    width: 100% !important;

    @media (max-width: 576px) {
      font-size: 14px !important;
    }

    &:lang(ar) {
      padding: 15px 60px 15px 15px;
    }
  }

  .flag-dropdown {
    border: none !important;
    border-radius: 7px 0 0 7px !important;
    width: 50px;

    &:lang(ar) {
      border-radius: 0 7px 7px 0 !important;
    }
  }

  .selected-flag {
    width: 100% !important;
    .flag {
      left: 12px !important;
    }

   

    &:lang(ar) {
      .arrow {
        left: 15px !important;
      }

      &:hover {
        border-radius: 0 7px 7px 0 !important;
      }
    }

    &:lang(ar) {
      &:focus {
        border-radius: 0 7px 7px 0 !important;
      }
    }
  }
}
