@import url(./../assets/fonts/fonts.css);
@import url('~animate.css/animate.css');

@import url('https://use.typekit.net/jvi1qwr.css');
@import url('https://fonts.googleapis.com/css2?family=Blaka&family=Blaka+Hollow&family=Noto+Sans+Arabic:wght@600&display=swap');

@import url('~owl.carousel/dist/assets/owl.carousel.css');
@import url('~owl.carousel/dist/assets/owl.theme.default.css');

@import 'sidebarCustom.scss';
@import 'congratsPopup.scss';
@import 'customMobileInput.scss';
@import 'customPlayer.scss';
@import 'videoPlayerCustom.scss';
@import 'dropdownCustom.scss';
@import 'videopopup.scss';

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: url(./../assets/images/BG/BGTopArea.png) no-repeat;
  background-size: cover;
  min-height: 100vh;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

body.bg1 {
  background-color: #fd9331;
}

body.bg1:before {
  content: ' ';
  position: absolute;
  z-index: -1;
  width: 100%;
  height: 350px;
  overflow: visible;
  left: 0;
  top: 0;
  background: url(./../assets/images/BG/BGTopArea.png) no-repeat center top;
  background-size: cover;

  @media(min-width: 2000px) {
    height: 450px;
  }
}

// body.bg11 {
//   background-color: #5331fd;
// }

// body.bg11:before {
//   content: ' ';
//   position: absolute;
//   z-index: -1;
//   width: 100%;
//   height: 350px;
//   overflow: visible;
//   left: 0;
//   top: 0;
//   background: url(./../assets/images/BG/BGTopArea.png) no-repeat center top;
//   background-size: cover;
//   transform: rotate(0deg) scaleX(-1);

//   @media(min-width: 2000px) {
//     height: 450px;
//   }
// }

body.bg2 {
  background-color: #ffdd58;
}

body.bg2:before {
  content: ' ';
  position: absolute;
  z-index: -1;
  width: 100%;
  height: 350px;
  overflow: visible;
  left: 0;
  top: 0;
  background: url(./../assets/images/BG/BGTopArea1.png) no-repeat center top;
  background-size: cover;

  @media(min-width: 2000px) {
    height: 450px;
  }
}

@media (min-width: 992px) {
  .use-spring-carousel-main-wrapper .use-spring-carousel-track-wrapper {
    justify-content: center !important;
  }
}
