@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";

.detailsPage {
    
	.banner {
		display: flex;
		flex-direction: column;
		// border: 3px solid red;
		align-items: center;
		max-width: 1400px;
		margin: 20px 20px 100px 13vw;

		@include media-breakpoint-down(lg) {
			margin: 20px auto 70px auto;
			padding: 0 20px;
		}

		&:lang(ar){
			margin: 20px 13vw 100px 0;
			direction: rtl;

			@include media-breakpoint-down(lg) {
				margin: 20px auto 70px auto;
				padding: 0 20px;
			}
		}

		.bannerImg {
			display: flex;
			max-width: 320px;
			height: auto;
			filter: drop-shadow(9px 9px 0px #1F9CE0);
			border-radius: 10px;
			// border: 3px solid green;
		}

		.txtwrap {
			display: flex;
			flex-direction: column;
			// border: 3px solid rgb(17, 0, 128);
			max-width: 800px;
			
			h1 {
				padding: 40px 10px 0;
				font-family: 'summeronregular';
				font-size: 27px;
				line-height: 48px;
				color: #FFFFFF;
				text-shadow: 3px 3px 0px #000000;
				

				&:lang(ar) {
					direction: rtl;
					font-size: 30px;
					font-family: omnes-arabic, sans-serif;
					font-style: normal;
					font-weight: 800;
				}
			}

			p {
				padding:0 10px;
				font-family: 'aktivgrotesk-reguuploadedfile';
				font-size: 16px;
				line-height: 20px;
				margin: 30px 0;
				color: #151515;

				&:lang(ar){
					direction: rtl;
					font-family: 'Noto Sans Arabic', sans-serif;
				}
			}

			.actionBar {
				padding: 30px 10px;

				.shareCTA {
					font-weight: 600;
					margin: 0 0 1.4rem 0;
					padding: 0;
					font-size: 21px;
				}

				@include media-breakpoint-down(md) {
					padding: 10px;
				}

				&:lang(ar){
					direction: rtl;

					@include media-breakpoint-down(md) {
						padding: 10px;
					}
				}
			} 
		}

			@include media-breakpoint-up(md) {
				flex-direction: row;
				justify-self: center;
				align-self: center;

				&:lang(ar){
					direction: rtl;
				}
				
				.txtwrap {
					padding: 40px;
				}
			}
    }

	.orangeSection {
		background-color: #FD9331;
		padding-top: 80px;

		@include media-breakpoint-down(md) {
			padding-top: 50px;
		}
	}

	.mysteryVideo {
		max-width: 500px;
		margin-bottom: 40px;
	}

	.ingWrap {
		display: flex;
		flex-direction: column;
		max-width: 1400px;
		margin: 20px 20px 0 13vw;
		padding-bottom: 20px;

		ul {
			font-weight: 600;
		}

		@include media-breakpoint-down(lg) {
			margin: 20px auto 0 auto;
			padding: 0 20px 30px 20px;
		}

		&:lang(ar){
			direction: rtl;
			margin: 20px  13vw  0  20px;

			@include media-breakpoint-down(lg) {
				margin: 20px auto 0 auto;
				padding: 0 20px 30px 20px;
			}
		}

		h4 {
			font-size: 22px;
			margin: 30px 0 40px 0;
			font-weight: 700;
			line-height: 25px;
			text-transform: uppercase;

			&:lang(ar) {
				direction: rtl;
				font-size: 30px;
				font-family: omnes-arabic, sans-serif;
				font-style: normal;
				font-weight: 800;
			}
		}
	}
}