@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';

.headerBar {
  transition: all 0.5s;
  width: 100%;
  padding: 20px;
  // padding: 20px 5px 20px 0;
  // margin-left: -10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 100px;
  z-index: 1;

  &:lang(ar) {
    flex-direction: row-reverse;
  }

  .logo {
    transition: all 0.5s;
    width: 93px;
    height: 81px;
  }

  @include media-breakpoint-up(md) {
    height: 150px;
    padding: 40px;

    .logo {
      width: 120px;
      height: 105px;
    }
  }

  .headerLeft {
    display: flex;
    align-items: center;
    gap: 4vw;

    &:lang(ar) {
      direction: rtl;
    }
  }
}
