@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";

.detailsPage {
  flex-direction: column;
  justify-content: center;
  align-content: center;
  display: flex;
  
  .banner{
    padding: 20px;
    display: flex;
    flex-direction: column;
    

    .bannerImg {
      display: flex;
      width: 320px;
      height: auto;
      filter: drop-shadow(9px 9px 0px #1F9CE0);
      border-radius: 10px;
    }

    .shareWrap {
      margin-top: 30px;
    }
    
    .txtwrap {
      display: flex;
      flex-direction: column;

        
      h1 {
        padding:40px 10px 0;
        font-family: 'summeronregular';
        font-size: 27px;
        line-height: 48px;
        color: #FFFFFF;
        text-shadow: 3px 3px 0px #000000;
          &:lang(ar) {
            direction: rtl;
            font-size: 30px;
            font-family: omnes-arabic, sans-serif;
            font-style: normal;
            font-weight: 800;
          }
        }

        p {
          padding:0 10px;
          font-family: 'aktivgrotesk-reguuploadedfile';
          font-size: 16px;
          line-height: 20px;
          margin: 30px 0;
          color: #151515;

          &:lang(ar){
            direction: rtl;
            font-family: 'Noto Sans Arabic', sans-serif;
          }
        }

        .actionBar {
          padding: 30px 10px;

          &:lang(ar) {
            direction: rtl;
            font-family: 'Noto Sans Arabic', sans-serif;
          }

          .shareCTA {
            font-weight: 600;
            margin: 0 0 1.4rem 0;
            padding: 0;
            font-size: 21px;
          }
        } 
      }

      @include media-breakpoint-up(sm) {
        max-width: 960px;
        flex-direction: row;
       
        &:lang(ar){
          flex-direction: row-reverse;
          display: rtl;
        }
    
        justify-self: center;
        align-self: center;
        
        .txtwrap {
          padding: 40px;
        }
      }
  }

  .ingWrap {
      display: flex;
      flex-direction: column;
      padding: 30px;
      width: 100%;
      justify-self: center;
      align-self: center;

      ul {
        font-weight: 600;
      }

      &:lang(ar){
        direction: rtl;
      }

      h4 {
        font-family: 'summeronregular';
        margin: 40px 10px 20px;
        font-style: normal;

          &:lang(ar) {
            direction: rtl;
            font-size: 30px;
            font-family: omnes-arabic, sans-serif;
            font-style: normal;
            font-weight: 800;
          }
      }

      @include media-breakpoint-up(lg) {
        width: 960px;
        padding: 60px;
      }
  }
}