@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';

#congratsPopup, #congratsPopupInProgress {

  .MuiPaper-root {
    padding: 6vh 6vw;
    background: url('./../assets/images/BG/BGTopAreaFlip.png') left bottom
        no-repeat,
      url('./../assets/images/BG/BGTopArea1.png') right top no-repeat;
    background-size: 140%;
    background-blend-mode: color-burn;
    background-attachment: fixed;
    background-color: #fd9331;

    border: 3px solid;
    border-radius: 5px !important;
    border-image-slice: 1;
    border-image-source: linear-gradient(to top, #fff0bc, #e86042) !important;

    @include media-breakpoint-down(lg) {
      background-size: 180%;
    }

    @include media-breakpoint-down(md) {
      padding: 3vh 4vw;
    }

    @include media-breakpoint-down(sm) {
      background-size: 300%;
    }
  }

  .MuiDialog-paper {
    max-width: 1000px;
    overflow: hidden;

    @include media-breakpoint-down(md) {
      overflow-y: auto;
    }

    @include media-breakpoint-down(sm) {
      margin: 0 20px;
    }
  }
}

#regSuccessPopup {
  .MuiPaper-root {
    padding: 6vh 6vw;

    background: url('./../assets/images/BG/BGTopAreaFlip.png') left bottom
        no-repeat,
      url('./../assets/images/BG/BGTopArea1.png') right top no-repeat;
    background-size: 140%;
    background-blend-mode: color-burn;
    background-color: #ffd84e;

    border: 3px solid;
    border-radius: 5px !important;
    border-image-slice: 1;
    border-image-source: linear-gradient(to top, #fff0bc, #e86042) !important;

    @include media-breakpoint-down(lg) {
      background-size: 180%;
    }

    @include media-breakpoint-down(md) {
      padding: 6vh 4vw;
    }

    @include media-breakpoint-down(sm) {
      background-size: 200%;
    }
  }

  .css-1t1j96h-MuiPaper-root-MuiDialog-paper {
    max-width: 1000px;

    @include media-breakpoint-down(sm) {
      margin: 0 20px;
    }
  }
}

.popupWrapper {
  display: flex;
  gap: 4vw;
  align-items: center;

  @include media-breakpoint-down(md) {
    flex-direction: column-reverse;
    gap: 4vh;
  }

  &:lang(ar) {
    direction: rtl;
  }
}

.popupContent {
  display: flex;
  flex-direction: column;
  z-index: 1;

  @include media-breakpoint-down(md) {
    align-items: center;
    text-align: center;
  }

  &:lang(ar) {
    align-items: unset;

    @include media-breakpoint-down(md) {
      align-items: center;
      text-align: center;
    }
  }

}

.popupDesc {
  margin-bottom: 1rem;
}

#alert-dialog-title, 
#alert-dialog-title-inProgress {
  font-family: 'summeronregular';
  font-size: 27px;
  line-height: 48px;
  color: #ffffff;
  text-shadow: 3px 3px 0px #000000;
  margin-bottom: 4vh;

  @include media-breakpoint-down(md) {
    margin-bottom: 3vh;
    line-height: 40px;
    font-size: 20px;
    max-width: 60vw;
  }

  @include media-breakpoint-down(sm) {
    max-width: 100%;
    font-size: 17px;
    line-height: 34px;
  }


  &:lang(ar) {
    font-family: 'omnes-arabic', sans-serif;
    font-weight: 800;
    font-style: normal;
    direction: rtl;
    font-size: 50px;
    line-height: 64px;
    margin-bottom: 4vh;

    @include media-breakpoint-down(md) {
      margin-bottom: 2vh;
      line-height: 50px;
      font-size: 40px;
    }

    @include media-breakpoint-down(sm) {
      font-size: 35px;
      line-height: 45px;
    }
  }
}

#alert-dialog-description, 
#alert-dialog-description-inProgress {
  font-size: 21px;
  line-height: 28px;
  color: #141414;
  margin-bottom: 6vh;

  @include media-breakpoint-down(md) {
    margin-bottom: 3vh;
    font-size: 18px;
  }

  &:lang(ar) {
    font-family: 'omnes-arabic', sans-serif;
    font-weight: 500;
    direction: rtl;
    margin-bottom: 6vh;

    @include media-breakpoint-down(md) {
      margin-bottom: 3vh;
    }
  }
}

.recipeLink {
  text-decoration: none;
  font-weight: 500;
  font-size: 21px;
  display: flex;
  align-items: center;
  color: #6a3806;
  gap: 10px;
  margin-top: 10px;

  &:hover {
    color: black;
  }

  @include media-breakpoint-down(md) {
    font-size: 18px;
    margin-top: 5px;
  }

  @include media-breakpoint-down(sm) {
    font-size: 16px;
    margin-top: 3px;
  }
}

.imageSunBG {
  display: flex;
  justify-content: center;

  &::after {
    content: ' ';
    background: url(./../assets/images/BG/sunBG.png) no-repeat center;
    background-size: cover;
    width: 100%;
    height: 100%;
    position: absolute;
  }

  @include media-breakpoint-down(md) {
    display: none;
  }
}

.imageSunBGInProgress {
  display: flex;
  justify-content: center;

  &::after {
    content: ' ';
    background: url(./../assets/images/BG/sunBG.png) no-repeat center;
    background-size: contain;
    width: 100%;
    height: 100%;
    position: absolute;
  }

  @include media-breakpoint-down(md) {
    display: none;
  }
}

.mysteryRecipeImage {
  border-radius: 10px;
  display: flex;
  -webkit-filter: drop-shadow(9px 9px 0 #30bdd0);
  filter: drop-shadow(9px 9px 0 #30bdd0);
  z-index: 1;
  
  @include media-breakpoint-down(md) {
    max-width: 40%;
    margin-bottom: 20px;
  }

  @include media-breakpoint-down(sm) {
    max-width: 50%;
  }
}

.sandwichImage {
  border-radius: 10px;
  display: flex;
  -webkit-filter: drop-shadow(9px 9px 0 #30bdd0);
  filter: drop-shadow(9px 9px 0 #30bdd0);
  z-index: 1;
  
  @include media-breakpoint-down(md) {
    max-width: 40%;
    margin-bottom: 20px;
  }

  @include media-breakpoint-down(sm) {
    max-width: 75%;
    margin-bottom: 10px;
  }
}

.imageSunBGMobile {
  display: none;

  @include media-breakpoint-down(md) {
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin-bottom: 20px;
    align-items: center;
    font-size: 14px;
    color: #141414;
    font-weight: 400;

    &::after {
      content: ' ';
      background: url(./../assets/images/BG/sunBG.png) no-repeat center;
      background-size: contain;
      width: 150%;
      top: 0;
      height: 100%;
      position: absolute;
      z-index: -1;
    }
  }
}

