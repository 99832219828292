@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';

.homeBanner {
  display: flex;
  padding-top: 20px;
  padding-bottom: 50px;
  justify-content: center;
  align-content: center;
  position: relative;
  background-color: #FFD84E;

  .wrapper {
    width: 500;
    max-width: 100vw;
    overflow: hidden;
    padding: 0;
    display: flex;
    flex-direction: column;
    align-items: center;

    h1 {
      padding: 0 20px;
      font-family: 'summeronregular';
      font-size: 27px;
      line-height: 48px;
      text-align: center;
      color: #ffffff;

      text-shadow: 3px 3px 0px #000000;

      &:lang(ar) {
        font-family: 'omnes-arabic', sans-serif;
        font-weight: 800;
        font-style: normal;
        direction: rtl;
        text-align: center;
        font-size: 58px;
        line-height: 70px;
      }
    }
    p {
      padding: 0 20px;
      font-family: 'aktivgrotesk-reguuploadedfile';
      font-size: 16px;
      line-height: 20px;
      margin: 20px 0 30px;
      z-index: 100;
      color: #151515;
      text-align: center;

      &:lang(ar) {
        font-family: 'Noto Sans Arabic', sans-serif;
        direction: rtl;
        margin-top: 0;
      }
    }
    .bannerImg {
      z-index: 1;
      transition: all 0.5s;
      position: relative;
      display: flex;
      width: 180%;
      top: -200px;
      justify-content: center;
      align-self: center;
      background-size: contain;
      height: auto;
    }
    .btn {
      z-index: 2;
      margin-top: -350px;
      padding-bottom: 50px;
    }
  }

  &:after {
    content: ' ';
    position: absolute;
    left: 0;
    bottom: 0;
    background: url(../../../assets/images/BG/BGTopAreaFlip1.png) no-repeat
      center top;
    width: 100%;
    height: 400px;
    background-size: cover;
  }

  @include media-breakpoint-up(sm) {
    .wrapper {
      max-width: 100vw;

      h1,
      p {
        max-width: 80%;
        text-align: center;
      }

      h1 {
        max-width: 80%;
      }

      p {
        max-width: 60%;
      }

      .bannerImg {
        width: 130vw;
        top: -230px;
      }
      .btn {
        margin-top: -420px;
      }
    }
  }

  @include media-breakpoint-up(md) {
    padding-top: 0px;
    padding-bottom: 100px;
    .wrapper {
      max-width: 80vw;

      h1,
      p {
        max-width: 80%;
        text-align: center;
      }
      .bannerImg {
        width: 100vw;
        top: -230px;
      }
    }
  }

  @include media-breakpoint-up(lg) {
    .wrapper {
      max-width: 100vw;

      h1,
      p {
        max-width: 900px;
        text-align: center;
      }

      h1 {
        font-size: 48px;
        line-height: 80px;
      }

      p {
        font-size: 21px;
        line-height: 28px;
      }

      .bannerImg {
        width: 1000px;
        top: -280px;
      }

      .btn {
        margin-top: -500px;
      }
    }
  }
}
