@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";

.Listing {
    display: flex;
    padding-bottom:50px;
    justify-content: center;
    align-content: center; 
    position: relative;
    background-color: #fd9331;
    
    .wrapper {
        z-index: 1;
        overflow: hidden;
        padding: 0px;;
        display: flex;
        flex-direction: column;
        
        h1 {
            padding:0 20px;
            font-family: 'summeronregular';
            font-style: normal;
            font-weight: 400;
            font-size: 27px;
            line-height: 48px;
            color: #FFFFFF;
            text-shadow: 3px 3px 0px #000000;
            text-align: center;

            @include media-breakpoint-down(sm) {
                align-self: flex-start;
            }

            &:lang(ar) {
                font-family: omnes-arabic, sans-serif;  
                font-weight: 800;
                font-style: normal;
                font-size: 33px;

                @include media-breakpoint-down(sm) {
                    align-self: flex-end;
                }
            }
        }

        .container {
            display: flex;
            justify-content: space-evenly;
            flex-wrap: wrap;
            flex-direction: row;
            padding: 20px 10px 0 20px;

            &:lang(ar){
                direction:rtl;
            }
            
            .item {
                margin: 10px;
                display: flex;
                align-items: center;
                flex-direction: row;
                overflow-x: hidden;   
                flex-direction: column;
                max-width: 100%;
                color: #141414!important;
                text-decoration:none!important;
                text-align: center;

                @include media-breakpoint-down(md) {
                    max-width: 250px;
                 }

                 @include media-breakpoint-down(sm) {
                    max-width: 180px;
                 }

                 @media(max-width: 450px) {
                    max-width: 130px;
                 }

                 @media(max-width: 350px) {
                    max-width: 110px;
                 }


                img {
                    width: 100%;
                    border-radius: 7px;
                    height: auto;
                }

                p {
                    padding: 20px 0 0;
                    display: flex;
                    font-size: 15px;

                    &:lang(ar){
                        font-family: 'Noto Sans Arabic', sans-serif;
                        direction: rtl;
                    }
                }
            }
        }
    }

    @include media-breakpoint-up(md) {
        .wrapper {
            max-width: 960px;
          
            .container {
                .item {
                    max-width: 250px;
                    p{
                        font-size: 18px;
                    }
                }
            }
        }
    }

    @include media-breakpoint-up(lg) {
        .wrapper {
            max-width: 80%;
            h1{ 
                justify-self: center;
            }
            

            .container {
                .item {
                    max-width: 250px;
                    margin: 20px;
                    p{
                        font-size: 16px;
                    }
                }
            }
        }
    }
}