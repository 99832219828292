@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";


.notFound {
    padding: 50px 0;
    display:flex;
    flex-direction:column;
    align-items: center;
    justify-content: center;

    img {
        margin-top: -30px;
        width: 320px;
        height: auto;
    }

    h1 {
        font-family: 'summeronregular';
        font-size: 40px;
        color: white;
        margin-top: -20px;
        text-shadow: 3px 3px 0px #000000;

        &:lang(ar) {
            font-family: omnes-arabic, sans-serif;  
            font-weight: 800;
            font-style: normal;
        }
    }
    p {
        padding:0 20px;
        font-family: 'aktivgrotesk-reguuploadedfile';
        font-size: 16px;
        line-height: 20px;
        text-align: center;
        margin: 30px 0;
        color: #151515;
        &:lang(ar) {
            font-family: 'Noto Sans Arabic', sans-serif;
        }
    }

    @include media-breakpoint-up(md) {
        min-height: 80vh;
        img {
            width: 500px;
        }
        p {
            padding-bottom: 50px;
        }
        h1 {
            margin-top: -40px;
        }

    }
}