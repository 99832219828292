/*! Generated by Font Squirrel (https://www.fontsquirrel.com) on October 7, 2022 */

@font-face {
  font-family: 'aktivgrotesk-thinuploadedfile';
  src: url('aktivgrotesk-thin-webfont.woff2') format('woff2'),
    url('aktivgrotesk-thin-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'aktivgrotesk-reguuploadedfile';
  src: url('aktivgrotesk-regular-webfont.woff2') format('woff2'),
    url('aktivgrotesk-regular-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'aktivgrotesk-mediuploadedfile';
  src: url('aktivgrotesk-medium-webfont.woff2') format('woff2'),
    url('aktivgrotesk-medium-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'aktivgrotesk-lighuploadedfile';
  src: url('aktivgrotesk-light-webfont.woff2') format('woff2'),
    url('aktivgrotesk-light-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'aktivgrotesk-bolduploadedfile';
  src: url('aktivgrotesk-bold-webfont.woff2') format('woff2'),
    url('aktivgrotesk-bold-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'summeronregular';
  src: url('summeron-webfont.woff2') format('woff2'),
    url('summeron-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'summeronregular';
  src: url('summeron-webfont.woff2') format('woff2'),
    url('summeron-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'coolveticaregular';
  src: url('coolvetica_rg-webfont.woff2') format('woff2'),
    url('coolvetica_rg-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  &:lang(ar) {
    font-family: 'Noto Sans Arabic', sans-serif;
  }
}

@font-face {
  font-family: 'vtc_letterer_proregular';
  src: url('vtc_letterer_pro_regular-webfont.woff2') format('woff2'),
    url('vtc_letterer_pro_regular-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

.OmnesArabicLight {
  font-family: omnes-arabic, sans-serif;
  font-weight: 400;
  font-style: normal;
}

.OmnesArabicSemiBold {
  font-family: omnes-arabic, sans-serif;
  font-weight: 700;
  font-style: normal;
}

.NotoSans {
  font-family: 'Noto Sans Arabic', sans-serif;
}
