@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";

.card {
    margin: 0 10px;
    
     .wrap {
        border-radius: 7px;
        width: 290px;
        height: 367px;
        justify-content: center;
        align-items: center;
        display: flex;
        flex-direction: column;
        position: relative;
        overflow: hidden;

        &:before {
            content: " ";
            position: absolute;
            width: 100%;
            height: 100%;
            left: 0;
            top: 0;
            background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.7) 85.42%);
        }
        img {
            height: 100%;
            width: auto;
        }

        span {
            padding: 20px;
            position: absolute;
            bottom: 0;
            font-family: 'coolveticaregular';
            font-size: 21px;
            line-height: 24px;
            color: #FFFFFF;
            width: 100%;
            min-height: 115px;
            text-align: center;

            &:lang(ar){
                font-family: 'Noto Sans Arabic', sans-serif;
                direction: rtl;
            }
        }
    }
}
