@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';

.decodeSection {
  display: flex;
  padding-bottom: 70px;
  position: relative;
  font-family: 'aktivgrotesk-reguuploadedfile';

  &:after {
    content: ' ';
    position: absolute;
    z-index: -1;
    left: 0;
    bottom: 0;
    background: url(../../../assets/images/BG/BGTopAreaFlip1.png) no-repeat
      center top;
    width: 100%;
    height: 350px;
    background-size: cover;
  }

  @include media-breakpoint-down(md) {
    padding-top: 2vh;
  }

  .wrapper {
    max-width: 100vw;
    overflow: hidden;
    display: flex;
    align-items: center;
    margin: 0 auto;
    padding: 0 20px;
    min-height: 30vh;

    &:lang(ar) {
      flex-direction: row-reverse;
    }

    h1 {
      font-family: 'summeronregular';
      font-size: 48px;
      line-height: 85px;
      color: #ffffff;
      text-shadow: 3px 3px 0px #000000;
      max-width: 600px;

      @include media-breakpoint-down(lg) {
        font-size: 28px;
        line-height: 65px;
      }

      &:lang(ar) {
        font-family: 'omnes-arabic', sans-serif;
        font-weight: 800;
        font-style: normal;
        direction: rtl;
        font-size: 58px;
        line-height: 70px;
        max-width: 600px;
      }
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    gap: 40px;
    width: 50%;

    @include media-breakpoint-down(md) {
      width: 100%;
      gap: 20px;
    }

    &:lang(ar) {
      align-items: flex-end;
      text-align: start;
    }

    p {
      font-size: 21px;
      line-height: 28px;
      color: #151515;
      max-width: 600px;

      @include media-breakpoint-down(md) {
        font-size: 16px;
      }

      &:lang(ar) {
        font-family: 'Noto Sans Arabic', sans-serif;
        direction: rtl;
      }
    }
  }

  .btn {

    &:lang(ar) {
      direction: rtl;
    }

    @include media-breakpoint-down(md) {
      margin: 0 auto;
    }
  }

  .imageWrap {
    width: 50%;
    max-width: 700px;
    display: flex;
    align-items: center;
    justify-content: center;

    @include media-breakpoint-down(md) {
      display: none;
    }

    &:after {
      content: ' ';
      position: absolute;
      z-index: -1;
      background: url(./../../../assets/images/BG/sunBG.png) no-repeat center;
      width: 100%;
      height: 100%;
      background-size: contain;
    }

    img {
      max-width: 100%;
      height: auto;
      transition: all 0.5s;
    }
  }

  .imageWrapMobile {
    display: none;

    @include media-breakpoint-down(md) {
      width: 100%;
      max-width: 700px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &:after {
      content: ' ';
      position: absolute;
      z-index: -1;
      background: url(./../../../assets/images/BG/sunBG.png) no-repeat center;
      width: 200%;
      height: 100%;
      background-size: contain;
    }

    img {
      max-width: 90%;
      height: auto;
      transition: all 0.5s;
    }
  }
}
