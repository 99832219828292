@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";


.Steps {
    display: flex;
    padding: 0px 0 80px;
    justify-content: center;
    align-content: center; 
    position: relative;
    background-color: #fd9331;
        
    .wrapper {
        width: 100vw;
        max-width: 100vw; 
        z-index: 1;
        overflow: hidden;
        padding: 0px;;
        display: flex;
        flex-direction: column;
        align-items: center;

        h1, p {
            text-align: center;
        }
        
        h1 {
            padding:20px;
            font-style: normal;
            font-size: 27px;
            text-align: left;
            color: black;
            
            font-family: 'summeronregular';
            margin: 40px 10px 20px;
            font-style: normal;

            &:lang(ar) {
                font-size: 30px;
                font-family: omnes-arabic, sans-serif;
                font-style: normal;
                font-weight: 800;
            }

            text-transform: capitalize;
        }

        h3, p{
            &:lang(ar){
                font-family: 'Noto Sans Arabic', sans-serif;
            }
        }
    
        .btn {
            z-index: 2;
        }
    }

    @include media-breakpoint-up(sm) {
        .wrapper {

            h1, p {
                max-width: 80%;
                text-align: center;
            }

            h1 {
                max-width: 80%;
            }

            p {
                max-width: 60%;
            }
        }
    }

    @include media-breakpoint-up(md) {
        .wrapper {
            h1, p {
                max-width: 80%;
            }
        }
    }

    @include media-breakpoint-up(lg) {
        .wrapper {
            h1, p {
                text-align: center;
            }
        }
    }
}


// ! for mystery page
.StepsMystery {
    display: flex;
    padding: 0px 0 80px;
    position: relative;
    background-color: #fd9331;
        
    .wrapper {
      width: 100vw;
      max-width: 100vw; 
      z-index: 1;
      overflow: hidden;
      padding: 0px;;
      display: flex;
      flex-direction: column;
      margin: 20px 0 80px 13vw;

      @include media-breakpoint-down(lg) {
        margin: 20px auto 50px auto;
        padding: 0 0 0 20px;
		}

        &:lang(ar) {
          align-items: flex-end;
          margin: 20px  13vw  80px 20px;

          @include media-breakpoint-down(lg) {
            margin: 20px auto 50px auto;
            padding: 0 20px 0 0;
          }
        }

        h1 {
            font-size: 22px;
			margin: 30px 0 40px 0;
			font-weight: 600;
			line-height: 25px;
            margin: 30px 0 40px 0;

            &:lang(ar) {
                direction: rtl;
                font-size: 30px;
                font-family: omnes-arabic, sans-serif;
                font-style: normal;
                font-weight: 800;
            }
        }

        h3, p{
            &:lang(ar){
            font-family: 'Noto Sans Arabic', sans-serif;
            }
        }
    
        .btn {
            z-index: 2;
        }
    }

    @include media-breakpoint-up(sm) {
        .wrapper {

            h1, p {
                max-width: 80%;
            }

            h1 {
                max-width: 80%;
            }

            p {
                max-width: 60%;
            }
        }
    }

    @include media-breakpoint-up(md) {
        .wrapper {
            h1, p {
                max-width: 80%;
            }
        }
    }
}