@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";


.Popular {
    display: flex;
    padding: 50px 0 80px;
    justify-content: center;
    align-content: center; 
    position: relative;
    background-color: #fd9331;

    &.hideBG {
        &:before {
            display: none;
        }
    }

    &:before {
        content: " ";
        position: absolute;
        z-index: 0;
        width: 100%;
        height: 350px;
        overflow: visible;
        left: 0;
        top: 0;
        background: url(../../../assets/images/BG/BGTopArea.png) no-repeat center top;
        background-size: cover;
    }
        
    .wrapper {
        max-width: 100vw; 
        z-index: 1;
        overflow: hidden;
        padding: 0px;;
        display: flex;
        flex-direction: column;
        align-items: center;

        h1, p {
            text-align: center;
        }
        
        h1 {
            padding:0 20px;
            font-family: 'summeronregular';
            font-style: normal;
            font-weight: 400;
            font-size: 27px;
            line-height: 48px;
            color: #FFFFFF;
            text-shadow: 3px 3px 0px #000000;

            &:lang(ar) {
                font-family: omnes-arabic, sans-serif;  
                font-weight: 800;
                font-style: normal;
                font-size: 33px;

                @include media-breakpoint-down(sm) {
                    align-self: flex-end;
                }
            }


            @include media-breakpoint-down(sm) {
                align-self: flex-start;
                text-align: left;
            }
        }

        p {
            padding:0 20px;
            font-family: 'aktivgrotesk-reguuploadedfile';
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 20px;
            margin: 30px 0;
            
            color: #151515;
            &:lang(ar){
                font-family: 'Noto Sans Arabic', sans-serif;
            }
        }
        .btn {
            z-index: 2;
        }

    }

    @include media-breakpoint-up(sm) {
        .wrapper {

            h1, p {
                max-width: 80%;
                text-align: center;
            }

            h1 {
                max-width: 80%;
            }

            p {
                max-width: 60%;
            }
        }
    }

    @include media-breakpoint-up(md) {
        .wrapper {
            h1, p {
                max-width: 80%;
            }
        }
    }

    @include media-breakpoint-up(lg) {
        padding-top: 80px;
        .wrapper {
            h1, p {
                max-width: 700px;
                text-align: center;
            }
        }
    }
}

// ! for yellow bg

.PopularYellow {
    display: flex;
    padding: 10vh 0 15vh 0;
    justify-content: center;
    align-content: center; 
    position: relative;
    background-color: #FFD84E;

		@include media-breakpoint-down(md) {
			padding: 10vh 0 10vh 0;
		}
        
    .wrapper {
			max-width: 100vw; 
			z-index: 1;
			overflow: hidden;
			padding: 0px;;
			display: flex;
			flex-direction: column;
			align-items: center;
			gap: 50px;

      h1, p {
        text-align: center;
      }
      
      h1 {
        font-family: 'summeronregular';
        font-style: normal;
        font-weight: 400;
        font-size: 27px;
        line-height: 48px;
        color: #FFFFFF;
        text-shadow: 3px 3px 0px #000000;

        &:lang(ar) {
          font-family: omnes-arabic, sans-serif;  
          font-weight: 800;
          font-style: normal;
          font-size: 33px;
        }
      }
      p {
        padding:0 20px;
        font-family: 'aktivgrotesk-reguuploadedfile';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;
        margin: 30px 0;
        color: #151515;

        &:lang(ar){
          font-family: 'Noto Sans Arabic', sans-serif;
        }
      }
      .btn {
          z-index: 2;
      }
    }

    @include media-breakpoint-up(sm) {
      .wrapper {
          max-width: 100vw;

          h1, p {
              max-width: 80%;
              text-align: center;
          }

          h1 {
              max-width: 80%;
          }

          p {
              max-width: 60%;
          }
      }
    }

    @include media-breakpoint-down(sm) {
        .wrapper {
          gap: 30px;
        }
    }

    @include media-breakpoint-up(md) {
        .wrapper {
          h1, p {
            max-width: 80%;
          }
        }
    }

    @include media-breakpoint-up(lg) {
      padding-top: 80px;

        .wrapper {

          h1, p {
            max-width: 700px;
            text-align: center;
          }
        }

    }
}