#quizDropdown {
  // border: 3px solid;
  width: 70%;
  cursor: pointer !important;

  .MuiInputBase-formControl {
    background-color: white;
    height: 50px;
    border-radius: 7px;
    border: none;
    text-align: left;
    font-family: 'aktivgrotesk-reguuploadedfile' !important;

    &:hover {
      border: none !important;
      outline: none !important;
    }
  }

  .MuiInputBase-input {
    &:lang(ar) {
      text-align: right !important;
      padding: 0 15px !important;
      font-family: 'Noto Sans Arabic', sans-serif !important;
      cursor: pointer !important;
    }
  }

  .selectPlaceholder {
    opacity: 0.5;
  }
}

.dropdownIcon {
  margin: 0 14px;
  cursor: pointer;
  position: absolute;
  right: 0;
  transition: 0.3s;

  &:lang(ar) {
    left: 0 !important;  
    right: unset !important;
  }
}

.MuiMenuItem-root {
  border-radius: 5px !important;
  margin: 0 4px !important;
  font-family: 'aktivgrotesk-reguuploadedfile' !important;
  // border: 1px solid saddlebrown !important;

  &:lang(ar) {
    direction: rtl;
    font-family: 'Noto Sans Arabic', sans-serif !important;
    font-weight: 600;
    padding-right: 7px !important;
  }
}

// #mysteryPage {
  .MuiPopover-paper {
    border-radius: 7px !important;
  }
// }