#page-wrap {
    .rhap_volume-controls {
        display: none !important;
    }

    .rhap_additional-controls {
        display: none !important;
    }


    .rhap_controls-section {
        flex: unset !important;

       
    }

    .rhap_header {
        font-weight: bold;
    }

    .rhap_container.rhap_loop--off.rhap_play-status--paused {
        background: #FFF6A9;
        box-shadow: none;
        border-radius: 7px;

       
    }

    .rhap_progress-indicator {
        background: #EE2D24;
        box-shadow: none;
    }

    .rhap_progress-bar-show-download {
        background-color: #CEC685;
    }

    button.rhap_button-clear.rhap_main-controls-button.rhap_play-pause-button {
        color: #ffca7a;
    }

    .rhap_time {
        color: #B68F55;
    }

    .rhap_header {
        margin-top: 10px;
        text-align: left;
        line-height: 20px;

        &:lang(ar) {
            text-align: right;
        }
    }
}
