#mysteryPage {

  .rhap_container {
    border-radius: 7px;
    background-color: #FFCA7A;
    box-shadow: none;
  }

  .rhap_horizontal {
    &:lang(ar) {
      flex-direction: row-reverse;
    }
  }

  .rhap_header {
    margin-bottom: 12px;
    text-align: center;
    font-weight: 600;

    &:lang(ar) {
      font-size: 18px;
      line-height: 0.5;
      font-family: 'Noto Sans Arabic', sans-serif;

      @media(max-width: 480px) {
        font-size: 16px;
      }
    }
  } 

  .rhap_volume-container {
    display: none;
  }

  .rhap_additional-controls {
    display: none;
  }

  .rhap_controls-section {
    flex: unset;
  }

  .rhap_main-controls-button {
    color: #FFF6A9;
  }

  .rhap_progress-filled {
    background: #CB312A;
    left: 0;
  }

  .rhap_time {
    font-size: 14px;
    color: #B68F55;
  }

  .rhap_download-progress,
  .rhap_progress-bar-show-download 
  {
    background: #DFB370; 
  }

  .rhap_volume-indicator, .rhap_progress-indicator {
    background: #EE2D24;
  }
}

// for quiz page global style to overwrite

.playButtonActive {
  background: #FFD84E !important;
  border: 2px solid #524516 !important;

  svg {
    path {
      fill: black !important;
    }
  }
}

// for carousel at product page
.owl-stage {
  margin: 0 auto !important;
}