.langBTN {
    display: flex;
    padding: 5px 12px;
    width: 92px;
    height: 40px;
    font-family:"vtc_letterer_proregular";
    font-weight: normal;
    text-align: center;
    font-size: 23px;
    text-decoration: none   ;
    justify-content:space-between;
    align-items: center;
    color: #0B0B0B!important;
    background: url("../../../assets/images/svg/langBTNBg.svg") no-repeat;

    &:before {
        content: " ";
        margin-top: 2px;
        height: 100%;
        width: 18px;
        background: url("../../../assets/images/svg/iconGlobe.svg") no-repeat center;
    }

    &:after {
        content: " ";
        height: 100%;
        width: px;
        //width: 18px;
        //background: url("../../../assets/images/svg/iconDownArrow.svg") no-repeat center;
    }
}