@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";

.card {
    margin: 0 10px;
    text-decoration: none!important;
    
    .wrap {
        width: 290px;
        height: 367px;
        box-shadow: 0px 5px 6px rgba(0, 0, 0, 0.4);
        border-radius: 7px;
        padding: 30px;
        justify-content: center;
        align-items: center;
        display: flex;
        flex-direction: column;

        @include media-breakpoint-down(sm) {
          width: 240px;
        }

        img {
            height: 100%;
            width: auto;
        }
        
        &:lang(ar){
            .use-spring-carousel-item .ProductCarousel_card__nLOs2 {
                flex-direction: column-reverse;
            }
        }
    }

    h3 {
        font-family: 'coolveticaregular';
        font-style: normal;
        font-weight: 400;
        font-size: 23px;
        text-align: center;
        margin: 30px 0 50px;
        line-height: 32px;
        // color: white;
        color: #282828;
        text-decoration: none!important;
        text-shadow: none;

        &:lang(ar) {
            font-family: 'Noto Sans Arabic', sans-serif;
            direction: rtl;
        }
    }
}