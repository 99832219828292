@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';

#videoPopup {
    z-index: 99999;

    .MuiPaper-root {
        //padding: 4.5vh 4.5vw;
        padding: 70px;
        background: url('./../assets/images/BG/BGTopAreaFlip.png') left bottom no-repeat, url('./../assets/images/BG/BGTopArea1.png') right top no-repeat;
        background-size: 140%;
        background-blend-mode: color-burn;
        background-attachment: fixed;
        background-color: #fd9331;
        border: 3px solid;
        border-radius: 5px !important;
        border-image-slice: 1;
        border-image-source: linear-gradient(to top, #e86042, #fff0bc) !important;

        @include media-breakpoint-down(lg) {
            background-size: 180%;
            padding: 70px 30px;
        }

        @include media-breakpoint-down(md) {
            padding: 3vh 4vw;
        }

        @include media-breakpoint-down(sm) {
            background-size: 300%;
        }
    }

    .MuiDialog-paper {
        //max-width: 1000px;
        overflow: hidden;
        max-height: unset;
        max-width: 769px;
        align-items: center;
        text-align: center;

        @include media-breakpoint-down(md) {
            overflow-y: auto;
        }

        @include media-breakpoint-down(sm) {
            margin: 0 20px;
        }

        &:lang(ar) {
            min-width: 769px;
            align-items: center;
        }

        @include media-breakpoint-down(lg) {
            &:lang(ar) {
                min-width: 70% !important;
            }

            max-width: 70% !important;
        }

        @include media-breakpoint-down(md) {
            &:lang(ar) {
                min-width: 80% !important;
            }

            max-width: 80% !important;
        }

        @include media-breakpoint-down(sm) {
            &:lang(ar) {
                min-width: 90% !important;
            }

            max-width: 90% !important;
        }
    }
}



.v_popupWrapper {
    //display: flex;
    gap: 4vw;
    align-items: center;
    overflow-y: hidden;
    display: block;
    width: 100%;

    @include media-breakpoint-down(md) {
        flex-direction: column-reverse;
        gap: 4vh;
    }

    &:lang(ar) {
        direction: rtl;
    }
}

.v_popupContent {
    display: flex;
    flex-direction: column;
    z-index: 1;
    align-items: center !important;

    .V_buttonWrapper {
        display: flex;
        width: 100%;
        margin: auto;
        padding: 12px;
        z-index: 999;
        //align-items: center;
        align-items: self-end;
        text-align: center;
        justify-content: space-around;

        .v_shareBtn {
            text-decoration: none;
            font-weight: 500;
            font-size: 21px;
            // display: flex;
            align-items: center;
            width: 50%;
            color: #6a3806;
            gap: 10px;
            margin-top: 10px;
            position: relative;

            &:after {
                content: ' ';
                background: url(./../assets/images/Home/whiteBtn.png) no-repeat center;
                background-size: cover;
                width: 100%;
                height: 100%;
                position: absolute;
                top: 0;
                bottom: 0;
                right: 0;
                left: 0;
                z-index: -1;
            }
        }

        .v_recipeLink {
            text-decoration: none;
            font-weight: 500;
            font-size: 21px;
            display: flex;
            align-items: center;
            color: #6a3806;
            gap: 10px;
            margin-top: 10px;
            width: 50%;

            &:hover {
                color: black;
            }

            @include media-breakpoint-down(md) {
                font-size: 18px;
                margin-top: 20px;
                width: 100%;
                justify-content: center;
                margin-bottom: 40px;
            }

            @include media-breakpoint-down(sm) {
                font-size: 16px;
                margin-top: 20px;
            }
        }

        @include media-breakpoint-down(md) {
            /*  flex-wrap: wrap;
            margin-bottom: 40px;*/
            margin-bottom: 0px;
            flex-wrap: wrap-reverse;
            margin-top: 40px;
        }
    }

    .v_closeBtnWrap {
        position: absolute;
        top: 16px;
        right: 16px;
        z-index: 999;
        cursor: pointer;
    }

    @include media-breakpoint-down(md) {
        align-items: center;
        text-align: center;
    }

    &:lang(ar) {
        align-items: unset;

        @include media-breakpoint-down(md) {
            align-items: center;
            text-align: center;
        }
    }
}



#v_alert-dialog-title {
    font-family: 'summeronregular';
    font-size: 27px;
    line-height: 64px;
    color: #ffffff;
    text-shadow: 3px 3px 0px #000000;
    margin-bottom: 4vh;
    z-index: 9999;


    @include media-breakpoint-down(md) {
        margin-bottom: 3vh;
        line-height: 40px;
        font-size: 20px;
        max-width: 60vw;
        //margin: 27px 0;
        margin: 40px 0;
    }

    @include media-breakpoint-down(sm) {
        max-width: 100%;
        font-size: 17px;
        //line-height: 34px;
    }


    &:lang(ar) {
        font-family: 'omnes-arabic', sans-serif;
        font-weight: 800;
        font-style: normal;
        direction: rtl;
        font-size: 50px;
        line-height: 64px;
        //margin: 40px 0;

        @include media-breakpoint-down(md) {
            width: 100% !important;
            margin: 60px 0 40px;
            line-height: 50px;
            font-size: 40px;
        }

        @include media-breakpoint-down(sm) {
            font-size: 35px;
            line-height: 45px;
            margin: 40px 0 40px !important;
        }

        @include media-breakpoint-down(lg) {
            width: 50%;
        }
    }
}





.v_imageSunBG {
    display: flex;
    justify-content: center;
    width: 100%;
    height: 100%;

    &::after {
        content: ' ';
        background: url(./../assets/images/BG/sunBG.png) no-repeat center;
        background-size: cover;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
    }

    @include media-breakpoint-down(md) {
        // display: none;
    }

    .video-js {
        z-index: 999 !important;
        padding-top: 56.25% !important;
    }

    .playBtn {
        z-index: 9999;
        position: absolute;
        height: 85px;
        width: 85px;
        cursor: pointer;
        margin: auto;
        //top: 0;
        top: 50px;
        bottom: 0;
        left: 0;
        right: 0;

        @include media-breakpoint-down(md) {
            height: 55px;
            width: 55px;
            //top: 70px;
            top: -30px;
        }
    }
}

#custom-inline-buttons{
  display: inline-flex !important;
}
