@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";


.card {
    margin: 0 10px;
    .wrap {
        width: 290px;
        height: 367px;
        padding: 10px;
        border-radius: 7px;
        display: flex;
        flex-direction: column;
        position: relative;
        background: #F0722E;
        color: #FFFFFF;

        h3 {
            padding: 20px;
            bottom: 0;
            font-family: 'coolveticaregular';
            font-size: 21px;
            line-height: 24px;
            text-shadow: none;
        }

        p {
            padding: 20px;
            text-align: left;
            bottom: 0;
            font-family: 'aktivgrotesk-reguuploadedfile';
            font-size: 16px;
            line-height: 24px;
            height: 100px;
            min-height: 200px;;
            text-shadow: none;
        }

        &:lang(ar){
            h3, p {
                direction: rtl;
                text-align: right;
            }
        }
    }

    .wrapYellow {
        width: 290px;
        height: 367px;
        padding: 10px;
        border-radius: 7px;
        display: flex;
        flex-direction: column;
        position: relative;
        background: #FFD84E;
        color: #141414;

        h3 {
            padding: 20px;
            bottom: 0;
            font-family: 'coolveticaregular';
            font-size: 21px;
            line-height: 24px;
            text-shadow: none;
        }

        p {
            padding: 20px;
            text-align: left;
            bottom: 0;
            font-family: 'aktivgrotesk-reguuploadedfile';
            font-size: 16px;
            line-height: 24px;
            height: 100px;
            min-height: 200px;;
            text-shadow: none;
        }

        &:lang(ar){
            h3, p {
                direction: rtl;
                text-align: right;
            }
        }
    }
}