@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';

.translateSection {
    display: flex;
    padding: 100px 0;
    margin-top: -17px;
    justify-content: center;
    align-content: center;
    // background-color: #FFDD58;
    background-color: #fd9332;
    //background-image: url("../../../assets/images/BG/randomizerBG.png");
    position: relative;
    font-family: 'aktivgrotesk-reguuploadedfile';

    &:before {
        content: " ";
        position: absolute;
        z-index: 1;
        top: 0;
        right: 0;
        //background: url("../../../assets/images/BG/BGTopArea1.png") no-repeat center top;
        background: url("../../../assets/images/BG/BGTopArea.png") no-repeat center top;
        width: 100%;
        height: 400px;
        background-size: cover;

        @include media-breakpoint-down(md) {
            display: none;
        }
    }

    &:after {
        content: " ";
        position: absolute;
        z-index: 1;
        left: 0;
        bottom: 0;
        //background: url("../../../assets/images/BG/BGTopAreaFlip.png") no-repeat center top;
        background: url(../../../assets/images/BG/BGTopAreaFlip1.png) no-repeat center top;
        width: 100%;
        height: 400px;
        background-size: cover;

        @include media-breakpoint-down(md) {
          //  display: none;
        }
    }

    @include media-breakpoint-down(md) {
        padding-top: 2vh;
    }

    .wrapper {
        max-width: 100vw;
        overflow: hidden;
        display: flex;
        align-items: center;
        margin: 0 auto;
        padding: 0 20px;
        min-height: 30vh;
        z-index: 9999;

        &:lang(ar) {
            flex-direction: row-reverse;
        }

        h1 {
            font-family: 'summeronregular';
            font-size: 48px;
            line-height: 85px;
            color: #ffffff;
            text-shadow: 3px 3px 0px #000000;
            margin: 0 0 40px 0;
            //max-width: 600px;

            @include media-breakpoint-down(lg) {
                font-size: 28px;
                line-height: 65px;
            }

            @include media-breakpoint-down(md) {
                margin: 40px 0 0 0;
            }

            &:lang(ar) {
                font-family: 'omnes-arabic', sans-serif;
                font-weight: 800;
                font-style: normal;
                direction: rtl;
                font-size: 58px;
                line-height: 70px;
                max-width: 600px;
                text-align: center;
            }
        }

        @include media-breakpoint-down(sm) {
            padding: 0 12px !important;
        }

        @include media-breakpoint-down(md) {
            padding: 0 20px;
        }

        @include media-breakpoint-down(lg) {
            padding: 0 30px;
        }

        @include media-breakpoint-down(xl) {
            padding: 0 30px;
        }
    }

    .content {
        display: flex;
        flex-direction: column;
        //gap: 40px;
        width: 50%;
        text-align: center;
        padding: 20px;
        //max-height: 579px;
        max-width: 600px;

        @include media-breakpoint-down(md) {
            width: 100%;
            gap: 20px;
            padding: 0;
        }

        @include media-breakpoint-down(lg) {
            gap: 20px;
            padding: 0;
            max-height: 594px;
        }

        @include media-breakpoint-down(xl) {
            padding: 0px;
        }

        &:lang(ar) {
            //align-items: flex-end;
            text-align: start;
        }

        p {
            font-size: 21px;
            line-height: 28px;
            color: #151515;
            max-width: 600px;
            margin: 20px 0 0 0 !important;


            @include media-breakpoint-down(md) {
                font-size: 16px;
            }

            &:lang(ar) {
                font-family: 'Noto Sans Arabic', sans-serif;
                direction: rtl;
                text-align: center;
            }
        }
    }



    .imageWrap {
        width: 50%;
        max-width: 700px;
        display: flex;
        align-items: center;
        //justify-content: center;
        justify-content: flex-end;
        margin-left: 100px;

        @include media-breakpoint-down(md) {
            display: none;
        }

        @include media-breakpoint-down(lg) {
            margin-left: 30px;
        }
        // &:after {
        //   content: ' ';
        //   position: absolute;
        //   z-index: -1;
        //   background: url(./../../../assets/images/BG/sunBG.png) no-repeat center;
        //   width: 100%;
        //   height: 100%;
        //   background-size: contain;
        // }

        img {
            max-width: 100%;
            height: auto;
            transition: all 0.5s;
        }

        &:lang(ar) {
            margin-left: 0px;
            margin-right: 100px;

            @include media-breakpoint-down(lg) {
                margin-right: 30px;
            }
        }
    }

    .imageWrapMobile {
        display: none;

        @include media-breakpoint-down(md) {
            width: 100%;
            max-width: 700px;
            display: flex;
            align-items: center;
            justify-content: center;
        }



        img {
            max-width: 90%;
            height: auto;
            transition: all 0.5s;
        }
    }
}
