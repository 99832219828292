.paperSeperator {
    position: relative;
    display: flex;
    flex-direction: column;
    z-index: 100;
    margin-top:-18px;
    &:before {
        content: " ";
        z-index: 1;  
        width: 100%;
        height: 30px;
        background: url(../../../assets/images/BG/white-sperator.png) repeat-x;
        background-size: cover;
        margin-top: -15px;
    }
}

.paperSeperatorTop {
    @extend .paperSeperator;
    &:before {
        margin-top: unset;
        // margin-bottom: -15px;
    }
}

.setMargin{
    margin-top: 0;

    &:before{
        margin-bottom: -67px;
        margin-top: -11px;
        background-color: transparent;
    }
}