@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';

.keyboardWrap {
  width: 100%;
  background: #e0832d;
  border: 5px solid #9b5c22;
  border-radius: 82px;
  padding: 6px 6px 40px 6px;

  @include media-breakpoint-down(md) {
    padding: 6px 6px 30px 6px;
  }
}

.keyboard {
  width: 100%;
  background: #ffad4e;
  border: 11px solid #e0832d;
  border-radius: 82px;
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  padding: 70px 80px;
  justify-content: space-evenly;

  @include media-breakpoint-down(md) {
    padding: 40px;
    gap: 10px;
  }

  @media (max-width: 365px) {
    padding: 15px;
  }
}

.keyboardArabic {
  width: 100%;
  background: #ffad4e;
  border: 11px solid #e0832d;
  border-radius: 82px;
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  padding: 70px 80px;
  justify-content: space-evenly;
  flex-direction: row-reverse;

  @include media-breakpoint-down(md) {
    padding: 40px;
    gap: 10px;
  }

  @media (max-width: 365px) {
    padding: 15px;
  }
}

.soundButton {
  font-size: 31px;
  font-weight: 600;
  color: #915620;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 14px 22px;
  width: 100px;
  height: 100px;
  background: #ffdf8b;
  border: 4px solid #e0832d;
  border-radius: 50%;
  -webkit-box-shadow: 0px 5px 0px 1px #9f6026;
  -moz-box-shadow: 0px 5px 0px 1px #9f6026;
  box-shadow: 0px 5px 0px 1px #9f6026;

  &:active {
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
  }

  @include media-breakpoint-down(md) {
    width: 80px;
    height: 78px;
    font-size: 25px;
  }

  @include media-breakpoint-down(sm) {
    width: 60px;
    height: 58px;
  }
}

.soundButtonAR {
  font-size: 31px;
  font-weight: 600;
  color: #915620;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 14px 22px;
  width: 100px;
  height: 100px;
  background: #ffdf8b;
  border: 4px solid #e0832d;
  border-radius: 50%;
  -webkit-box-shadow: 0px 5px 0px 1px #9f6026;
  -moz-box-shadow: 0px 5px 0px 1px #9f6026;
  box-shadow: 0px 5px 0px 1px #9f6026;
  

  font-family: 'omnes-arabic', sans-serif;
  font-weight: 600;
  font-style: normal;
  direction: rtl;
  font-size: 40px;

  &:active {
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
  }

  @include media-breakpoint-down(md) {
    width: 80px;
    height: 78px;
    font-size: 25px;
  }

  @include media-breakpoint-down(sm) {
    width: 60px;
    height: 58px;
  }
}