@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";

.randomizer {
    display: flex;
    padding: 100px 0;
    margin-top: -17px;
    justify-content: center;
    align-content: center; 
    background-color: #FFDD58;
    background-image: url("../../../assets/images/BG/randomizerBG.png");
    position: relative;
    .wrapper {
        position: relative;
        padding: 0px;
        display: flex;
        flex-direction: column;
        align-items: center;


        &:lang(ar){
            direction: rtl;
        }
        
        h1 {
            padding:0 20px;
            font-family: 'summeronregular';
            font-size: 27px;
            line-height: 48px;
            color: #FFFFFF;
            text-shadow: 3px 3px 0px #000000;
            text-align: center;
            
            &:lang(ar) {
                font-family: omnes-arabic, sans-serif;  
                font-weight: 800;
                font-style: normal;
                direction: rtl;
                font-size: 58px;
                line-height: 70px;
            }
        }
        p {
            padding:20px;
            font-family: 'aktivgrotesk-reguuploadedfile';
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 20px;
            text-align: center;
            
            color: #151515;
            &:lang(ar){
                font-family: 'Noto Sans Arabic', sans-serif;
                direction: rtl;
                padding: 0;
            }
        }
        .btn {
            margin-top: 50px;
            z-index: 2;
        }

        .container {
            position: relative;
            min-height: 400px;
            
            .card {
                margin: 0 10px;
                z-index: 3;
                position: absolute;
                top: 50%;
                left: 50%;
                margin-left: -145px;
                margin-top: -183px;
                .wrap {
                    width: 290px;
                    height: 367px;
                    border-radius: 7px;
                    justify-content: center;
                    align-items: center;
                    display: flex;
                    flex-direction: column;
                    position: relative;
                    overflow: hidden;
    
                    &:before {
                        content: " ";
                        position: absolute;
                        width: 100%;
                        height: 100%;
                        left: 0;
                        top: 0;
                        background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.7) 85.42%);
                    }
                    img {
                        height: 100%;
                        width: auto;
                    }
    
                    span {
                        padding: 20px;
                        position: absolute;
                        bottom: 0;
                        font-family: 'coolveticaregular';
                        font-size: 21px;
                        line-height: 24px;
                        color: #FFFFFF;
                        &:lang(ar){
                            font-family: 'Noto Sans Arabic', sans-serif;
                        }
                    }
                }
            }

            .player {
                width: 300px;
                height: 300px;
                max-width: 800px;
                position: relative;
                &:after {
                    content: " ";
                    border: 5px solid #FFDD58;
                    /*border-image: url("../../../assets/images/BG/randomizerBG.png");*/
                    width: 100%;
                    height: 100%;
                    position: absolute;
                    left: 0;
                    top:0;
                }           
            }
        }
    }

    &:before {
        content: " ";
        position: absolute;
        z-index: 1;
        top: 0;
        right: 0;
        background: url("../../../assets/images/BG/BGTopArea1.png") no-repeat center top;

        width: 100%;
        height: 400px;
        background-size: cover;

    }

    &:after {
        content: " ";
        position: absolute;
        z-index: 1;
        left: 0;
        bottom: 0;
        background: url("../../../assets/images/BG/BGTopAreaFlip.png") no-repeat center top;

        width: 100%;
        height: 400px;
        background-size: cover;

    }

    @include media-breakpoint-up(sm) {
        .wrapper {
            max-width: 100vw;

            h1, p {
                max-width: 80%;

            }

            h1 {
                max-width: 80%;
            }

            p {
                max-width: 60%;
            }

            .player {
                width: 500px;
                height: 500px;
            }
        }
    }

    @include media-breakpoint-up(md) {
        .wrapper {
            max-width: 80vw;

            h1, p {
                max-width: 80%;
            }
        }
    }

    @include media-breakpoint-up(lg) {
        .wrapper {
            max-width: 100vw;
            padding-bottom: 30px;

            h1, p {
                max-width: 900px;
            }

            h1 {
                font-size: 48px;
                line-height: 80px;
            }
    
            p {
                font-size: 21px;
                line-height: 28px;
            }
        }
    }
}