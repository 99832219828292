@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';

.mysteryPage {
  max-width: 2000px;
  margin: 0 auto;
  padding: 0 25px 40px 25px;
  background: url('./../../assets/images/BG/BGTopAreaFlip.png') no-repeat;
  background-position-x: left;
  background-position-y: bottom;

  @media(min-width: 2000px) {
    max-width: 100%;
  }
}

.title {
    font-family: 'summeronregular';
    font-size: 37px;
    line-height: 67px;
    color: #FFFFFF;
    text-shadow: 3px 3px 0px #000000;
    text-align: center;
    margin-bottom: 120px;
    margin-top: 20px;
  
    &:lang(ar) {
      direction: rtl;
      font-size: 40px;
      font-family: omnes-arabic, sans-serif;
      font-style: normal;
      font-weight: 800;
    }

    @include media-breakpoint-down(sm) {
      margin-bottom: 100px;
      font-size: 32px;
    }
}

.prizesWrap {
  display: flex;
  justify-content: space-around;
  cursor: default;
  gap: 45px;
  margin-bottom: 60px;

  @media(min-width: 2000px) {
    justify-content: center;
    gap: 10vw;
  }

  @include media-breakpoint-down(md) {
    flex-direction: column;
    align-items: center;
  }
}

.prize {
  max-width: 320px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  gap: 30px;
  z-index: 2;

  &:nth-child(2) {
    margin-bottom: 30px;
    margin-top: -30px;
    overflow: visible;
    transform: scale(1.1);
    z-index: -1;
    
    &:after {
      content: ' ';
      position: absolute;
      z-index: -2;
      left: 0;
      top: 20px;
      background: url(./../../assets/images/BG/sunBG.png) no-repeat center;
      width: 100%;
      height: 50%;
      opacity: 0.8;
      transform: scale(2);
      background-size: cover;
    }
  }
}

.prize1 {

  @include media-breakpoint-down(md) {
    order: -1;
  }
}

.prizeImage {
  width: 100%;

  &::selection {
    background-color: transparent;
  }
}

.prizePaper {
  width: 100%;

  &::selection {
    background-color: transparent;
  }
}

.prizeTitle {
  font-size: 22px;
  font-weight: 700;
  max-width: 300px;
  text-align: center;
  letter-spacing: -0.3px;
  padding: 0 10px;

  &::selection {
    background-color: transparent;
  }

  @media (max-width: 1060px) { 
    font-size: 17px;
  }

  @media (max-width: 870px) { 
    font-size: 15px;
  }

  @include media-breakpoint-down(md) {
    font-size: 20px;
  }

  @media (max-width: 380px) { 
    font-size: 16px;
  }
}

.prizeTitle1 {
  position: absolute;
  bottom: 33px;
  rotate: -3deg;
  // color: green;

  @media (max-width: 950px) { 
    bottom: 27px;
  }

  @media (max-width: 870px) { 
    bottom: 20px;
  }

  @include media-breakpoint-down(md) {
    bottom: 36px;
  }

  @media (max-width: 340px) { 
    bottom: 30px;
  }
}
.prizeTitle2 {
  position: absolute;
  bottom: 17px;
  rotate: 2deg;

  @media (max-width: 950px) { 
    bottom: 11px;
  }

  @media (max-width: 870px) { 
    bottom: 4px;
  }

  @include media-breakpoint-down(md) {
    bottom: 20px;
  }

  @media (max-width: 340px) { 
    bottom: 12px;
  }
}

.prizeTitle3 {
  position: absolute;
  // color: violet;
  bottom: 20px;
  rotate: -4deg;

  @media (max-width: 950px) { 
    bottom: 13px;
  }

  @media (max-width: 870px) { 
    bottom: 5px;
  }

  @include media-breakpoint-down(md) {
    bottom: 20px;
  }

  @media (max-width: 340px) { 
    bottom: 12px;
  }
}

.button {
  display: flex;
  align-items: center;
  justify-content: center;

  &:lang(ar) {
    direction: rtl;
  }
}