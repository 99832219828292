.video-js .vjs-tech {
  border-radius: 10px !important;
}

.video-js.vjs-fluid:not(.vjs-audio-only-mode), .video-js.vjs-16-9:not(.vjs-audio-only-mode), .video-js.vjs-4-3:not(.vjs-audio-only-mode), .video-js.vjs-9-16:not(.vjs-audio-only-mode), .video-js.vjs-1-1:not(.vjs-audio-only-mode) {
  border-radius: 10px !important;
}

.vjs-has-started .vjs-control-bar, .vjs-audio-only-mode .vjs-control-bar {
  border-radius: 0 0 10px 10px !important;
}

.video-js .vjs-control-bar {
  background-color: transparent !important;
}

.video-js .vjs-picture-in-picture-control, 
.video-js .vjs-fullscreen-control {
  display: none !important;
}