.static {
    padding: 20px;
    max-width: 960px;
    background-color: #fd9331;
    margin: 0 auto;
    h1 {
        font-family: 'summeronregular';
        font-size: 25px;
        line-height: 180%;;

        &:lang(ar) {
            font-family: omnes-arabic, sans-serif;  
            font-weight: 800;
            font-style: normal;
        }
    }

    p {
        font-family: 'aktivgrotesk-reguuploadedfile';
        padding: 10px 0;
        &:lang(ar){
            font-family: 'Noto Sans Arabic', sans-serif;
        }
    }

}